export default {
  identity(data, _args) {
    if (_.isEmpty(data)) return {data: []};
    return data;
  },

  selectivePickFilter(data, args) {
    if (_.isEmpty(data)) return {data: []};
    if (_.isEmpty(args)) return data;

    const filtered = _.map(data.data, (d) => {
      const p = _.pick(d, args);
      return p;
    } );

    return {data: filtered, meta: data.meta, filters: data.filters};
  },

  passthrough(data, args) {
    if (_.isEmpty(data)) return {data: []};
    return {data: args.data, filters: data.filters, meta: data.meta};
  },

  visualGrouper(data,args) {
    if (_.isEmpty(data)) return {data: []};

  }
}