import Helpers from '../../../mixins/helpers'

const testData = {
  general_admin_counts_1: {
    filters: {},
    data: {}
  }
};

const historyItem1 = {
  id: Helpers.methods.generateId(),
  dashboard: 'general_admin_counts',
  filters: {},
  title: 'Administration counts',
  data: {},
  state: 'loading'
};

const historyItem2 = {
  id: Helpers.methods.generateId(),
  dashboard: 'general_admin_counts',
  filters: {},
  title: 'Administration counts',
  data: {},
  state: 'done'
};

const historyItem3 = {
  id: Helpers.methods.generateId(),
  dashboard: 'general_admin_counts',
  filters: {},
  title: 'Administration counts',
  data: {},
  state: 'loading'
};

let data = {};
data[historyItem1.id] = historyItem1;
data[historyItem2.id] = historyItem2;
data[historyItem3.id] = historyItem3;

export const history = {
  data: data
};