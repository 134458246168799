export default {
  patientOmittedBreakdownFilter(data, _args) {
    if (_.isEmpty(data)) return {data: []};

    const drugAttr = data.filters.drug_view_attr;
    const totalPlaceholder = data.meta.total_placeholder;

    const totalItem = _.find(data.data, (item) => item[drugAttr] === totalPlaceholder);
    let out = [];
    let totalOmitted = 0;
    _.forEach(data.data, (item) => {
      if(item[drugAttr] === '-1') return;
      item.all_doses = totalItem.total_doses;
      item.admin_reason_label = `${item.admin_reason_id_label} ${item.admin_reason_detailed_id_label ? ` - ${item.admin_reason_detailed_id_label} `: ''}`
      out.push(item);
      totalOmitted += item.total_doses;
    })

    _.forEach(out, (item) => {
      item.percentage_of_total = ((item.total_doses / item.all_doses)*100).toFixed(1)
    })

    let meta = data.meta;
    meta.all_doses = totalItem.total_doses;
    meta.all_omitted = totalOmitted;

    meta.location = `<b>${data.data[0].location_name}</b>` || ''

    if(data.data[0].room_name) {
      meta.location += ` room <b>${data.data[0].room_name}</b>`
    }

    if(data.data[0].bed_name) {
      meta.location += ` bed <b>${data.data[0].bed_name}</b>`
    }

      return {data: out, filters: data.filters, meta: meta};
  },

  patientUnvalidatedDrugBreakdownFilter(data, _args) {
    if (data.data.length === 0) return data;

    // So the totals etc are stored in each record sent so all we need is the first one
    let data_item = data.data[0]
    let order_types = ['single', ]

    let meta = data.meta;
    meta.total_order_count = data_item.overall_order_count;
    meta.unvalidated_order_count = data_item.unvalidated_order_count;
    meta.unvalidated_percentage = ((data_item.unvalidated_order_count / data_item.overall_order_count)*100).toFixed(1)

    meta.location_name = `<b>${data_item.location_name}</b>` || ''

    if(data_item.room_name) {
      meta.location_name += ` room <b>${data_item.room_name}</b>`
    }

    if(data_item.bed_name) {
      meta.location_name += ` bed <b>${data_item.bed_name}</b>`
    }

    // Now loop through the values processing as we go
    _.forEach(data.data, (item) => {

      item.order_drug_validation_description = [];
      item.order_drug_validation_abbrevs = [];

      item.order_drug_validations.forEach(function callback(validation, index) {
        switch(validation) {
          case 0:
            item.order_drug_validation_description.push('Not Requested');
            item.order_drug_validation_abbrevs.push('NR');
            break;
          case 1:
            item.order_drug_validation_description.push('Unvalidated');
            item.order_drug_validation_abbrevs.push('U');
            break;
          case 2:
            item.order_drug_validation_description.push('Validated');
            item.order_drug_validation_abbrevs.push('V');
            break;
          default:
            item.order_drug_validation_description.push('');
            item.order_drug_validation_abbrevs.push('');
        };
      })

      switch(item.validated) {
        case 0:
          item.validation_order = 2
          break;
        case 1:
          item.validation_order = 1
          break;
        case 2:
          item.validation_order = 3
          break;
        default:
          item.validation_order = 3
      };

      item.order_formatted_doses = [];

      item.order_drug_doses.forEach(function callback(dose, index) {
        let formatted_dose = dose ? Number(dose).toString() : '';
        let units = item.order_drug_units[index];

        item.order_formatted_doses.push([formatted_dose, units].join(' ').trim());
      })

      item.order_formatted_prns = [];

      item.order_drug_prns.forEach(function callback(prn, index) {
        let formatted_prn = prn === 1 ? 'PRN' : '';
        item.order_formatted_prns.push(formatted_prn);
      })

      item.order_formatted_stats = [];

      item.order_drug_stats.forEach(function callback(stat, index) {
        let formatted_stat = stat === 1 ? 'STAT' : '';
        item.order_formatted_stats.push(formatted_stat);
      })

      item.order_formatted_link_types = [];

      item.order_link_types.forEach(function callback(link, index) {
        let link_type = ['Single', 'Then', 'Or', 'And', 'VDO']
        item.order_formatted_link_types.push(link_type[link]);
      })
    })

    let scheduledOrders = [];
    let prnOrders = [];
    let statOrders = [];

    _.forEach(data.data, (item) => {
      let checker = arr => arr.every(v => v === 1);

      if (checker(item.order_drug_prns)){
        prnOrders.push(item);
      } else if (checker(item.order_drug_stats)){
        statOrders.push(item);
      } else {
        scheduledOrders.push(item);
      }
    })

    data.data.scheduledOrders = scheduledOrders;
    data.data.prnOrders = prnOrders;
    data.data.statOrders = statOrders;

    let tabs = [{title: `SCHEDULED (${data.data.scheduledOrders.length}/${meta.total_order_count})`, name: 'tabScheduled'},
      {title: `PRN (${data.data.prnOrders.length}/${meta.total_order_count})`, name: 'tabPrn'},
      {title: `STAT (${data.data.statOrders.length}/${meta.total_order_count})`, name: 'tabStat'}]

    meta.tabs = tabs;

    let reordered_data = data.data.sort((a, b) => (a.validation_order > b.validation_order) ? 1 : -1)

    return {data: reordered_data, filters: data.filters, meta: meta};
  }
}