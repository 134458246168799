import Vue from 'vue';
import {User, Hospital} from '../../api';
import AxiosDefaults from 'axios/lib/defaults';

import {
  hospitals,
  active_user,
  current_user,
  user_view_model,
  extractors,
  data_logs,
  errors,
  data_operations,
} from './state/user';
import {subscribe} from '../../../channels/triscribe_channel';

import {
  SET_ERRORS, SET_USER, SET_HOSPITALS, SET_USER_IN_LIST, ADD_HOSPITAL, DELETE_USER, DELETE_HOSPITAL,
  SET_DATA_INFO, SET_CURRENT_USER, SET_DATA_STATUS, SET_USER_PASSWORD_CHANGE_URL, SET_EXTRACTORS,
  SET_DATA_LOGS, DELETE_DATA_LOG, SET_EXTRACTOR, DELETE_NOTIFICATION, SET_ERROR_INFO, DELETE_ERROR,
  DELETE_ERROR_LIKE_THIS, SET_DATA_OPERATIONS, SET_DATA_OPERATION
}
  from './types/user_types';

const state = {
  hospitals: hospitals,
  current_user: current_user,
  vm: user_view_model,
  active_user: active_user,
  extractors: extractors,
  data_operations: data_operations,
  data_logs: data_logs,
  errors: errors,
};

const actions = {
  async fetchHospitals({commit}) {
    const response = await Hospital.fetchHospitals();
    commit(SET_HOSPITALS, {hospitals: response.data});
    return response.data;
  },

  async addUser({commit}, {user}) {
    try {
      const response = await User.addUser(user);
      commit(SET_USER_IN_LIST, {user: response.data});
      return response.data;
    } catch (e) {
      throw e.response.data;
    }
  },

  async updateUser({commit}, {user}) {
    try {
      const response = await User.updateUser(user);
      commit(SET_USER_IN_LIST, {user: response.data});
      return response.data;
    } catch (e) {
      throw e.response.data;
    }
  },

  async addHospital({commit}, {hospital}) {
    const response = await Hospital.addHospital(hospital);
    commit(ADD_HOSPITAL, {hospital: response.data});
    return response.data;
  },

  async deleteUser({commit}, {user}) {
    await User.deleteUser(user.ref);
    commit(DELETE_USER, {username: user.username});
  },

  async deleteHospital({commit}, {hospital}) {
    await Hospital.deleteHospital(hospital.ref);
    commit(DELETE_HOSPITAL, {username: hospital.username});
  },

  async resetHospitalData({commit}, {systemType}) {
    const response = await Hospital.resetHospitalData(systemType);
    commit(SET_DATA_INFO, {tenant_data: response.data});
    return response.data;
  },

  async recalculateHospitalData({commit}, {name}) {
    const response = await Hospital.recalculateHospitalData(name);
    commit(SET_DATA_INFO, {tenant_data: response.data});
    return response.data;
  },

  async rebuildHospitalData({commit}, {names}) {
    const response = await Hospital.rebuildHospitalData(names);
    commit(SET_DATA_INFO, {tenant_data: response.data});
    return response.data;
  },

  async vacuumHospitalData({commit}, {vacType}) {
    const response = await Hospital.vacuumHospitalData(vacType);
    return response.data;
  },

  async fetchActiveUser({commit}) {
    const response = await User.fetchActiveUser();
    commit(SET_USER, {user: response.data});
    return response.data;
  },

  async setCurrentUser({commit}, data) {
    if(!data.ref) {
      return;
    }

    commit(SET_CURRENT_USER, data)
    const userInfo = await User.fetchUser(data.ref)
    commit(SET_CURRENT_USER, userInfo.data)
  },

  setDataStatus({commit}, data) {
    commit(SET_DATA_STATUS, data)
  },

  async login({commit, state}, {username, password}) {
    try {
      const response = await User.login(username, password);
      $('meta[name=csrf-token]').attr('content', response.data.csrf);
      AxiosDefaults.headers.common['X-CSRF-Token'] = response.data.csrf;
      commit(SET_CURRENT_USER, response.data);
      response.data.redirect_url = state.current_user.last_url
      return response.data
    } catch (err) {
      commit(SET_CURRENT_USER,{ref: null, hospital_ref: null, email: null, username: null,
        role_ids: null, roles_available: null, roles: null, tenant_id: null, tenant: null, api_token: null});
      throw err
    }
  },

  async logout({commit}) {
    const response = await User.logout();

    $('meta[name=csrf-token]').attr('content', response.data.csrf);
    AxiosDefaults.headers.common['X-CSRF-Token'] = response.data.csrf;

    commit(SET_CURRENT_USER, {ref: null, hospital_ref: null, email: null, username: null,
                              role_ids: null, roles_available: null, roles: null, tenant_id: null, tenant: null});
    return response.data;
  },

  forcePasswordChange({commit}, {token, password}) {
    return User.forcePasswordChange(token, password);
  },


  forcePasswordChangeCheck({commit}, {token}) {
    return User.forcePasswordChangeCheck(token);
  },

  async forcePasswordChangeUrl({commit}, {user}) {
    const response = await User.forcePasswordChangeUrl(user);
    commit(SET_USER_PASSWORD_CHANGE_URL, {user: user, url: response.data.url});
    return response.data.url;
  },

  forgottenPassword({commit}, {login}) {
    return User.forgottenPassword(login)
  },

  passwordReset({commit}, {token, password}) {
    return User.resetPassword(token, password);
  },

  changePassword({commit}, {existingPassword, newPassword}) {
    return User.changePassword(existingPassword, newPassword);
  },

  refreshData({commit}, {username}) {
    Hospital.refreshData(username)
  },

  saveTenantData({commit}, {tenant_data}) {
    User.saveTenantData(tenant_data);
  },

  loadDataFromS3({commit}, {level}) {
    User.loadDataFromS3(level);
  },

  async fetchDataLogs({commit}) {
    const response = await Hospital.fetchDataLogs();
    commit(SET_DATA_LOGS, {data_logs: response.data});
    return response.data;
  },

  async deleteDataLog({commit}, {dataLog}) {
    await Hospital.deleteDataLog(dataLog);
    commit(DELETE_DATA_LOG, {dataLog: dataLog})
  },

  async rerunDenormDataLog({commit}, {dataLog, table}) {
    await Hospital.rerunDataLogDenorm(dataLog, table)
  },

  async fetchExtractors({commit}) {
    const response = await Hospital.fetchExtractors();
    commit(SET_EXTRACTORS, {extractors: response.data});
    return response.data;
  },

  async fetchExtractor({commit}, {ref}) {
    const response = await Hospital.fetchExtractor(ref);
    commit(SET_EXTRACTOR, {extractor: response.data});
    return response.data;
  },

  async fetchDataOperations({commit}) {
    const response = await Hospital.fetchDataOperations();
    commit(SET_DATA_OPERATIONS, {data_operations: response.data});
    return response.data;
  },

  async runDataOpAction({commit}, {operation, action}) {
    const response = await Hospital.runDataOperation(operation, action);
    commit(SET_DATA_OPERATION, {data_operation: response.data});
    return response.data;
  },

  saveExtractor({commit}, {extractor}) {
    Hospital.saveExtractor(extractor);
  },

  async deleteNotification({commit}, {notification}) {
    await Hospital.deleteNotification(notification);
    commit(DELETE_NOTIFICATION, {notification: notification})
  },

  saveAndPublishExtractor({commit}, {extractor}) {
    Hospital.saveExtractorAndPublish(extractor);
  },

  async fetchErrors({commit}, {page}) {
    const response = await Hospital.fetchErrors(page);
    commit(SET_ERRORS, {errors: response.data});
    return response.data;
  },

  async fetchErrorInfo({commit}) {
    const response = await Hospital.fetchErrorInfo();
    commit(SET_ERROR_INFO, {info: response.data});
    return response.data;
  },

  async deleteError({commit}, {error}) {
    await Hospital.deleteError(error);
    commit(DELETE_ERROR, {error: error})
  },

  async deleteErrorLikeThis({commit}, {error}) {
    const response = await Hospital.deleteErrorLikeThis(error);
    commit(DELETE_ERROR_LIKE_THIS, {ids: response.data})
  },

  async fetchDefaultUserSettings({commit}) {
    const response = await User.fetchDefaultUserSettings();
    return response.data;
  },
};

const mutations = {
  [SET_USER](state, {user}) {
    const roles = _.map(user.role_ids, (id) => _.find(user.roles_available, (role) => role.id === id).ref);

    Vue.set(state.active_user, 'ref', user.ref);
    Vue.set(state.active_user, 'hospital_ref', user.hospital_ref);
    Vue.set(state.active_user, 'username', user.username);
    Vue.set(state.active_user, 'email', user.email);
    Vue.set(state.active_user, 'role_ids', user.role_ids);
    Vue.set(state.active_user, 'roles_available', user.roles_available);
    Vue.set(state.active_user, 'roles', roles);
    Vue.set(state.active_user, 'tenant_id', user.tenant_id);
    Vue.set(state.active_user.tenant, 'name', user.tenant.name);
    Vue.set(state.active_user.tenant, 'tenant_data', user.tenant.tenant_data);
    Vue.set(state.active_user.tenant, 'users', {});
    _forOwn(user.tenant.users, (u, username) => Vue.set(state.active_user.tenant.users, username, u))
  },

  [SET_HOSPITALS](state, {hospitals}) {
    _forOwn(hospitals, (hos, username) => Vue.set(state.hospitals, username, hos))
  },

  [SET_USER_IN_LIST](state, {user}) {
    Vue.set(state.active_user.tenant.users, user.username, user);
  },

  [ADD_HOSPITAL](state, {hospital}) {
    Vue.set(state.hospitals, hospital.username, hospital);
  },

  [DELETE_USER](state, {username}) {
    Vue.delete(state.active_user.tenant.users, username);
  },

  [DELETE_HOSPITAL](state, {username}) {
    Vue.delete(state.hospitals, username);
  },

  [SET_DATA_INFO](state, {tenant_data}) {
    _.assign(state.active_user.tenant.tenant_data, tenant_data)
  },

  [SET_CURRENT_USER](state, data) {
    if (state.current_user) {
      subscribe();
    }

    data.roles = _.map(data.role_ids, (id) => _.find(data.roles_available, (role) => role.id === id).ref);

    _.assign(state.current_user, data)
  },

  [SET_DATA_STATUS](state, data) {
    Vue.set(state.active_user.tenant.tenant_data, data.name, data)
  },

  [SET_USER_PASSWORD_CHANGE_URL](state, {user, url}) {
    // user.force_password_change_url = url
    Vue.set(state.active_user.tenant.users[user.username], 'force_password_change_url', url)
  },

  [SET_EXTRACTORS](state, {extractors}) {
    Vue.set(state.extractors, 'extractors', extractors)
  },

  [SET_DATA_LOGS](state, {data_logs}) {
    Vue.set(state.data_logs, 'data_logs', data_logs)
  },

  [DELETE_DATA_LOG](state, {dataLog}) {
    const idx = _.findIndex(state.data_logs.data_logs, dl => dl.id === dataLog.id);
    Vue.delete(state.data_logs.data_logs, idx);
  },

  [SET_EXTRACTOR](state, {extractor}) {
    Vue.set(state.extractors, 'extractor', extractor)
  },

  [SET_DATA_OPERATIONS](state, {data_operations}) {
    Vue.set(state.data_operations, 'data_operations', data_operations);
  },

  [SET_DATA_OPERATION](state, {data_operation, action}) {
    const idx = _.findIndex(state.data_operations.data_operations, dop => dop.id === data_operation.id);
    if (action === 'delete_op') {
      Vue.delete(state.data_operations.data_operations, idx);
    } else {
      state.data_operations.data_operations[idx] = data_operation
    }
  },

  [DELETE_NOTIFICATION](state, {notification}) {
    const idx = _.findIndex(state.extractors.extractor.notifications, n => n.id === notification.id);
    Vue.delete(state.extractors.extractor.notifications, idx);
  },

  [SET_ERRORS](state, {errors}) {
    Vue.set(state.errors, 'errors', errors)
  },

  [SET_ERROR_INFO](state, {info}) {
    Vue.set(state.errors, 'info', info)
  },

  [DELETE_ERROR](state, {error}) {
    const idx = _.findIndex(state.errors.errors, e => e.id === error.id);
    Vue.delete(state.errors.errors, idx);
  },

  [DELETE_ERROR_LIKE_THIS](state, {ids}) {
    let idxs = [];
    _.each(state.errors.errors, (val, idx) => {
      if (ids.indexOf(val.id) === -1) idxs.push(idx)
    });
    _.each(idxs, idx => Vue.delete(state.errors.errors, idx));
  },

};

export default {
  namespace: true,
  state,
  actions,
  mutations
}