import Vue from 'vue'
import {Dashboard, Cache} from '../../api';
import {dashboard, standard_filter_info_json} from './state/dashboard.js.erb';

import {
  SET_FILTERS,
  SET_DASHBOARD_INFO, SET_DASHBOARD_DATA,
  SET_FILTER, SET_DRUG_OPTIONS_CACHE,
  SET_DRUG_GROUP_DATA, SET_WARD_OPTIONS_CACHE, SET_DIRTY, FILTER_HISTORY_ADD,
  FILTER_HISTORY_UPDATE, UPDATE_FILTER_CACHE, HANDLE_SHARED_FILTERS, SET_GROUP_OPTIONS_ID_CACHE,
  RESET_DASHBOARD_INFO, SET_DRUG_CACHE, SET_FETCHED, SET_PATIENT_OPTIONS_CACHE
} from './types/dashboard_types';
import {store} from "../index";

const state = dashboard;

const validAttrs = ['vtm_id', 'vmp_id', 'trade_family_id', 'amp_id', 'mapped_drug_id'];

const options = ['drug_group_options', 'ward_group_options', 'ward_options',
  'admin_reason_options', 'specialty_options', 'epm_options', 'route_options', 'impact_indicator_options',
  'group_options', 'admin_reason_group_options', 'form_options', 'chart_type_options', 'y_axis_options',
  'breakdown_options', 'date_options'];

const option_processing = {
  // admin_reason_options: {
  //   method: 'jsonify_options',
  //   process: [{lookup: 'admin_reason_attr'}],
  //   extract: ['label']
  // }
}

function jsonify_options(opts, data, config) {
  let out = {};
  if (_.isEmpty(opts)) return out;

  Object.entries(opts).forEach(entry => {
    const [key, value] = entry;
    const processedKey = JSON.parse(key);
    out[processedKey] = {};
    config.extract.forEach(attr => out[processedKey][attr] = value[attr]);
    config.process.forEach(info => {
      Object.keys(value).forEach(key => {
        if (key.replace(/\s/g, '') === JSON.stringify(data[info.lookup])) {
          out[processedKey][data[info.lookup]] = value[key]
        }
      })
    });
  })
  return out;
}

const actions = {
  async fetchDashboardInfo({commit, dispatch}, {name: name}) {
    const dashResponse = await Dashboard.fetchDashboardInfo(name);

    const filters = dashResponse.data.default_filters;
    if (filters.drug_ids && filters.drug_view_attr && _.includes(validAttrs, filters.drug_view_attr)) {
      await dispatch('populateDrugOptionsCache', {data: filters});
      // await dispatch('populateDrugFullProductCache', {data: filters.drug_ids});
    }

    const drugMeta = dashResponse.data.default_filters.drug_meta;
    if (drugMeta) {
      const d = {
        substance_ids: _.map(filters.drug_info, 'substance_id'),
        meta_substance_id: drugMeta.substance_id
      }
      await dispatch('populateDrugCache', {data: d});
    }

    await dispatch('populateWardOptionsCache', {data: dashResponse.data.default_filters});

    if (!_.isEmpty(dashResponse.data.group_options)) {
      dispatch('populateGroupOptionsIdCache', {data: dashResponse.data.group_options});
    }

    commit(SET_DASHBOARD_INFO, dashResponse.data);

    return dashResponse.data;
  },

  resetDashboardInfo({commit}, data) {
    commit(RESET_DASHBOARD_INFO);
  },

  setDashboardData({commit}, {name, data}) {
    commit(SET_DASHBOARD_DATA, name, data);
  },

  setDashboardInfo({commit}, data) {
    commit(SET_DASHBOARD_INFO, data);
  },

  setFilters: async ({commit, state, dispatch}, data) => {
    if(!state.dashboards[data.dashboard].fetched) {
      state.dashboards[data.dashboard].fetching = true;
      await dispatch('fetchDashboardInfo', {name: data.dashboard});
      state.dashboards[data.dashboard].fetching = false;
      state.dashboards[data.dashboard].fetched = true;
    }
    commit(SET_FILTERS, data);
  },

  setFetched: ({commit}, data) => {
    commit(SET_FETCHED, data);
  },

  setFilter: ({commit}, data) => {
    commit(SET_FILTER, data);
    if (data.markDirty || typeof data.markDirty === 'undefined') {
      commit(SET_DIRTY, {dashboard: data.dashboard, value: true});
    }
  },

  addToFilterHistory({commit}, data) {
    commit(FILTER_HISTORY_ADD, data);
    commit(SET_DIRTY, {dashboard: data.dashboard, value: false});
  },

  updateFilterCache({commit}, data) {
    commit(UPDATE_FILTER_CACHE, data);
  },

  updateLastFilterHistory({commit}, data) {
    commit(FILTER_HISTORY_UPDATE, data);
  },

  handleSharedFilters({commit}, data) {
    commit(HANDLE_SHARED_FILTERS, data);
  },

  addToCache({commit}, data) {
    if (data.wards) {
      commit(SET_WARD_OPTIONS_CACHE, data.wards);
    }

    if (data.drugs) {
      if (typeof data.drugs[0] === Object) {
        commit(SET_DRUG_CACHE, data.drugs);
      } else {
        commit(SET_DRUG_OPTIONS_CACHE, data.drugs);
      }
    }
  },

  async populateDrugOptionsCache({commit}, {data: data}) {
    const drugIdCache = await Cache.populateDrugIdCache(data, store.state.dashboard.drug_id_cache);
    if (!_.isEmpty(drugIdCache)) {
      commit(SET_DRUG_OPTIONS_CACHE, drugIdCache);
    }
  },

  async populateDrugCache({commit}, {data: data}) {
    const drugCache = await Cache.populateDrugInfoCache(data, store.state.dashboard.drug_cache);
    if (!_.isEmpty(drugCache)) {
      commit(SET_DRUG_CACHE, drugCache);
    }
  },

  updateOptionsCache({commit}, {name, data}) {
    let mutation = SET_WARD_OPTIONS_CACHE;
    if (name === 'drug_cache') {
      mutation = SET_DRUG_CACHE
    } else if(name === 'drug_id_cache') {
      mutation = SET_DRUG_OPTIONS_CACHE
    }  else if(name === 'patient_id_cache') {
      mutation = SET_PATIENT_OPTIONS_CACHE
    }
    commit(mutation, data)
  },

  async populateWardOptionsCache({commit}, {data: data}) {
    const wardIdCache = await Cache.populateWardIdCache(data, store.state.dashboard.ward_id_cache);
    if (!_.isEmpty(wardIdCache)) {
      commit(SET_WARD_OPTIONS_CACHE, wardIdCache);
    }
  },

  populateGroupOptionsIdCache({commit}, data) {
    commit(SET_GROUP_OPTIONS_ID_CACHE, data);
  },
};

const mutations = {
  [SET_DRUG_GROUP_DATA](state, {data}) {
    for (const name in state.dashboards) {
      if (state.dashboards[name].available_filters.drug_group_grouping_ids ||
        state.dashboards[name].available_filters.drug_group_ids) {

        if (!_.isEmpty(data.hierarchy) && _.isEmpty(state.dashboards[name].drug_group_options.hierarchy)) {
          state.dashboards[name].drug_group_options.hierarchy = [];
        }
        state.dashboards[name].drug_group_options.hierarchy = state.dashboards[name].drug_group_options.hierarchy.concat(data.hierarchy);

        if (!_.isEmpty(data.mapper) && _.isEmpty(state.dashboards[name].drug_group_options.mapper)) {
          state.dashboards[name].drug_group_options.mapper = {};
        }
        state.dashboards[name].drug_group_options.mapper = {...state.dashboards[name].drug_group_options.mapper, ...data.mapper}
      }
    }
  },

  [SET_DASHBOARD_DATA](state, {query, data}) {
    Vue.set(state.drug_data, query, Object.freeze(data));
  },

  [SET_DASHBOARD_INFO](state, data) {
    state.dashboards[data.name].available_filters = data.available_filters;
    state.dashboards[data.name].silent_filters = data.silent_filters;
    state.dashboards[data.name].shared_filters = data.shared_filters;
    state.dashboards[data.name].filter_actions = data.filter_actions;

    for (const filter in data.default_filters) {
      state.dashboards[data.name].default_filters[filter] = data.default_filters[filter];
    }

    for (const item of options) {
      let opts = data[item];
      if (option_processing[item]) {
        if (option_processing[item].method === 'jsonify_options') {
          opts = jsonify_options(opts, data.default_filters, option_processing[item])
        }
      }
      state.dashboards[data.name][item] = opts;
    }

    state.dashboards[data.name].fetched = true;
    state.dashboards[data.name].fetching = false;
  },

  [RESET_DASHBOARD_INFO]() {
    _.forOwn(state.dashboards, (_obj, dashboardName) => {
        state.dashboards[dashboardName] = JSON.parse(standard_filter_info_json);
      }
    );
  },

  [SET_FETCHED](state, data) {
    state.dashboards[data.dashboard].fetched = true;
  },

  [SET_FILTERS](state, data) {
    for (const filter in data.filters) {
      state.dashboards[data.dashboard].filters[filter] = data.filters[filter];
    }
  },

  [SET_FILTER](state, {dashboard, name, value}) {
    Vue.set(state.dashboards[dashboard].filters, name, value);
  },

  [SET_DRUG_CACHE](state, cache) {
    state.drug_cache = Object.assign({}, state.drug_cache, cache)
  },

  [SET_DRUG_OPTIONS_CACHE](state, cache) {
    state.drug_id_cache = Object.assign({}, state.drug_id_cache, cache)
  },

  [SET_WARD_OPTIONS_CACHE](state, cache) {
    state.ward_id_cache = Object.assign({}, state.ward_id_cache, cache)
  },

  [SET_PATIENT_OPTIONS_CACHE](state, cache) {
    state.patient_id_cache = Object.assign({}, state.patient_id_cache, cache)
  },

  [SET_DIRTY](state, {dashboard, value}) {
    state.dashboards[dashboard].dirty = value
  },

  [FILTER_HISTORY_ADD](state, {dashboard, filters}) {
    state.dashboards[dashboard].filter_history.push(_.cloneDeep(filters));
  },

  [HANDLE_SHARED_FILTERS](state, {dashboard}) {
    if (state.dashboards[dashboard].filter_version < state.filter_cache['version']) {
      for (const sharedFilter in state.dashboards[dashboard].shared_filters) {
        let val = state.filter_cache[sharedFilter];
        if (val !== undefined && val !== null) {
          if (state.dashboards[dashboard].filters[sharedFilter] !== state.filter_cache[sharedFilter]) {
            state.dashboards[dashboard].filters[sharedFilter] = state.filter_cache[sharedFilter];
            state.dashboards[dashboard].dirty = true;
          }
        }
      }

      state.dashboards[dashboard].filter_version = state.filter_cache['version'];
    }
  },

  [FILTER_HISTORY_UPDATE](state, {dashboard, filters}) {
    const last = state.dashboards[dashboard].filter_history[state.dashboards[dashboard].filter_history.length - 1];
    Object.keys(filters).forEach(filter => Vue.set(last, filter, filters[filter]));
  },

  [UPDATE_FILTER_CACHE](state, {dashboard, sharedFilters, filters}) {
    let version = Date.now();

    for (const filter in sharedFilters) {
      if (filter in filters) {
        state.filter_cache[filter] = filters[filter];
      }
    }

    state.filter_cache['version'] = version;
    state.dashboards[dashboard].filter_version = version;
  },

  [SET_GROUP_OPTIONS_ID_CACHE](state, {data}) {
    if (!_.isEmpty(state.group_options_id_cache)) {
      return;
    }

    state.group_options_id_cache = {}
    data.forEach((item) => {
      item.groups.forEach((group) => {
        state.group_options_id_cache[group.id] = group;
      })
    })
  }
};

export default {
  namespace: true,
  state,
  actions,
  mutations,
}