import {HospitalSetting} from "../../api";
import {
  SET_HOSPITAL_SETTINGS,
  ADD_BURDEN_WEIGHTING,
  DELETE_BURDEN_WEIGHTING,
  DELETE_WARD_CHECK
} from "./types/hospital_setting_types";
import {hospital_setting} from './state/hospital_setting';

const state = hospital_setting;

const actions = {

  async fetchHospitalSettings({commit}) {
    const req = await HospitalSetting.fetchHospitalSettings();
    commit(SET_HOSPITAL_SETTINGS, {data: req.data})
  },

  async fetchWardIds() {
    const req = await HospitalSetting.wardIds();
    return req.data;
  },

  async addBurdenWeighting({commit}) {
    const weighting = {
      group_id: state.vm.groups[0].groups[0].id,
      weight: 1
    };
    const req = await HospitalSetting.addAdminBurdenWeighting(weighting);
    commit(ADD_BURDEN_WEIGHTING, {data: req.data})
  },

  updateBurdenWeighting({commit}, {data}) {
    HospitalSetting.updateAdminBurdenWeighting(data);
  },

  async deleteBurdenWeighting({commit}, {data}) {
    HospitalSetting.deleteAdminBurdenWeighting(data)
    commit(DELETE_BURDEN_WEIGHTING, data)
  },

  async saveWardCheckConfig() {
    HospitalSetting.saveWardCheckConfig({ward_check_config: state.ward_check_config});
  },

  async deleteWardCheck({commit}, {data}) {
    commit(DELETE_WARD_CHECK, {data: data})
  }
};

const mutations = {
  [SET_HOSPITAL_SETTINGS](state, {data}) {
    state.admin_burden_weightings = data.admin_burden_weightings;
    state.admin_burden_levels = data.admin_burden_levels;
    state.vm.groups = data.vm.groups;
    state.ward_check_config = data.ward_check_config;
  },

  [ADD_BURDEN_WEIGHTING](state, {data}) {
    state.admin_burden_weightings.push(data)
  },

  [DELETE_BURDEN_WEIGHTING](state, {data}) {
    state.admin_burden_weightings.splice(state.admin_burden_weightings.indexOf(data), 1);
  },

  [DELETE_WARD_CHECK](state, {data}) {
    state.ward_check_config.splice(state.ward_check_config.indexOf(data), 1);
  }
};

export default {
  namespace: true,
  state,
  actions,
  mutations
}