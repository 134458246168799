<template>
  <div v-if="errors.length > 0 || infos.length > 0 || warnings.length > 0 || successes.length > 0"
       class="flash-message">
    <transition name="fade">
      <div v-if="errors.length > 0" class="alert alert-danger">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
        <ul class="mb-0" v-if="errors.length > 1">
          <li v-for="e in errors" v-html="e"></li>
        </ul>
        <p v-if="errors.length === 1" v-html="errors[0]"></p>
      </div>
    </transition>

    <div v-if="warnings.length > 0" class="alert alert-warning">
      <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
      <ul class="mb-0" v-if="warnings.length > 1">
        <li v-for="e in warnings" v-html="e"></li>
      </ul>
      <p v-if="warnings.length === 1" v-html="warnings[0]"></p>
    </div>

    <div v-if="infos.length > 0" class="alert alert-info">
      <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
      <ul class="mb-0" v-if="infos.length > 1">
        <li v-for="e in infos" v-html="e"></li>
      </ul>
      <p v-if="infos.length === 1" v-html="infos[0]"></p>
    </div>

    <div v-if="successes.length > 0" class="alert alert-success">
      <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
      <ul class="mb-0" v-if="successes.length > 1">
        <li v-for="e in successes" v-html="e"></li>
      </ul>
      <p v-if="successes.length === 1" v-html="successes[0]"></p>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    data() {
      return {
        errors: [],
        infos: [],
        warnings: [],
        successes: [],
      }
    },

    mounted() {
      this.$flash.$on('add:error', (message, options = {}) => {
        this.errors.push(message);
        if (options.timeout) {
          setTimeout(()=> {
            this.errors = []
          }, options.timeout)
        }
      });

      this.$flash.$on('add:info', (message, options = {}) => {
        this.infos.push(message);
        if (options.timeout) {
          setTimeout(()=> {
            this.infos = []
          }, options.timeout)
        }
      });

      this.$flash.$on('add:warning', (message, options = {}) => {
        this.warnings.push(message);
        if (options.timeout) {
          setTimeout(()=> {
            this.warnings = []
          }, options.timeout)
        }
      });

      this.$flash.$on('add:success', (message, options = {}) => {
        this.successes.push(message);
        if (options.timeout) {
          setTimeout(()=> {
            this.successes = []
          }, options.timeout)
        }
      });
      this.$flash.$on('clear', () => {
        this.errors = [];
        this.infos = [];
        this.warnings = [];
        this.successes = [];
      });
    }
  }
</script>

<style>
  .flash-message {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    width: 60%;
    margin: auto;
  }

  .flash-message p {
    text-align: center;
  }

  .flash-message .close {
    font-size: 18px;
    line-height: 10px;
    margin: 0;
    font-size: 26px;
    font-weight: 300;
    line-height: 0.75;
    text-shadow: none;
    color: #fff;
  }

  .flash-message .close:active, .flash-message .close:focus {
    outline: none;
  }

  .flash-message .fade-enter-active, .flash-message .fade-leave-active {
    transition: opacity .5s;
  }

  .flash-message .fade-enter, .flash-message  .fade-leave-to
  {
    opacity: 0;
  }
</style>