export default {
  usageFilter(data, _args) {
    if (_.isEmpty(data)) return {data: []};
    if (_.isEmpty(data.meta)) return data;

    let collection = {};
    _.forEach(data.data, (item) => {
      collection[item.drug_label] ||= {}
      collection[item.drug_label][item.ward_label] ||= 0
      collection[item.drug_label][item.ward_label] += item.count
    })

    let out = [];
    _.forEach(Object.keys(collection), (drugLabel) => {
      _.forEach(Object.keys(collection[drugLabel]), (wardLabel) => {
        out.push({drug_label: drugLabel, ward_label: wardLabel, count: collection[drugLabel][wardLabel]})
      })
    })

    return {data: (data.data ? out : null), filters: data.filters, meta: data.meta};
  },
}