import consumer from "./consumer"

export function subscribe() {
  consumer.subscriptions.create("TriscribeChannel", {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // We have a response so process the data etc and update the store
      app.__vue__.$store.dispatch('queryDataReceived', data);
    },
  });
}