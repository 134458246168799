export default {
  omittedReasonsDataFilter(data, _args) {
    if (_.isEmpty(data)) return {data: []};
    if (_.isEmpty(data.meta)) return data;

    const totalsIdStr = data.meta.totals_id
    const omittedTotalsIdStr = data.meta.omitted_total_id

    let out = [];
    _.forEach(data.data, (item) => {
      Object.keys(item).forEach((key) => {
        if (key === 'date') return;

        if (key === totalsIdStr || key === omittedTotalsIdStr) return;

        item[`${key}_percent`] = item[totalsIdStr] !== 0 ? ((item[key] / item[totalsIdStr]) * 100).toFixed(2) : 0;
        item[`${key}_omitted_percent`] = item[omittedTotalsIdStr] !== 0 ? ((item[key] / item[omittedTotalsIdStr]) * 100).toFixed(2) : 0;

        data.meta.labels[`${key}_percent`] = data.meta.labels[key]
      });
      item[`${omittedTotalsIdStr}_percent`] = item[omittedTotalsIdStr] !== 0 ? ((item[omittedTotalsIdStr] / item[totalsIdStr]) * 100).toFixed(2) : 0;
      data.meta.labels[`${omittedTotalsIdStr}_percent`] = data.meta.labels[omittedTotalsIdStr];
      out.push(item)
    });

    return {data: out, filters: data.filters, meta: data.meta};
  },

  omittedReasonsWardCompareDataFilter(data, _args) {
    if (_.isEmpty(data)) return {data: []};
    if (_.isEmpty(data.meta)) return data;

    let out = [];
    _.forEach(data.data, (item) => {
      Object.keys(item).forEach((key) => {
        if (key === 'date') return;

        item[`${key}_percent`] = item[key].total_admins !== 0 ? ((item[key].reason_count / item[key].total_admins) * 100).toFixed(2) : 0;
        data.meta.labels[`${key}_percent`] = data.meta.labels[key]
      });
      out.push(item)
    });

    return {data: out, filters: data.filters, meta: data.meta};
  },

  omittedDosesEarlyLateTrendsDataFilter(data, _args) {
    if (_.isEmpty(data)) return {data: []};
    if (_.isEmpty(data.meta)) return data;

    let out = [];
    _.forEach(data.data, (item) => {
      Object.keys(item).forEach((key) => {
        if (key === 'date' || key === 'total') return;

        item[`${key}_percent`] = item.total && item.total !== 0 ? ((item[key] / item.total) * 100).toFixed(2) : 0;
        data.meta.labels[`${key}_percent`] = data.meta.labels[key]
      });
      out.push(item)
    });

    return {data: out, filters: data.filters, meta: data.meta};
  },
}