
const filter_values = JSON.stringify({
  date_range_type: 'custom',
  date_range_options_type: 'past',
  date_range_start: null,
  date_range_end: null,
  time_start: null,
  time_end: null,
  drug_group_id: null,
  ward_group_id: null,
  ward_grouping_type: 'existing',
  drug_grouping_type: 'existing',
  drug_group_grouping_ids: null,
  drug_group_ids: null,
  drug_ids: null,
  ward_group_ids: null,
  route_codes: null,
  form_codes: null,
  age_range_start: null,
  age_range_end: null,
  sex_id: null,
  date_grouping: null,
  multi_date_range: [{start: null, end: null, type: null}],
  ward_id: null,
  ward_ids: null,
  anticholinergics_score: null,
  admin_reason_id: null,
  admin_reason_ids: null,
  admin_reason_group_id: null,
  admin_reason_group_ids: null,
  admin_reason_attr: null,
  admin_reason_grouping_type: 'existing',
  specialty_codes: null,
  epm_id: 'all',
  admin_delay_delta: null,
  bed_days_enabled: false,
  bed_days_count: 100,
  average_enabled: false,
  average_interval: null,
  impact_indicator_code: null,
  group_id: null,
  burden_count_grouping: null,
  stock_last_issued_periods: null,
  order_length: null,
  stay_length: null,
  within_length: null,
  admin_delay_id: null,
  counts_enabled: null,
  threshold_value: null,
  event_relativity: null,
  chart_type: null,
  time_delay_group: null,
  percentage_of_enabled: false,
  percentage_of_value: null,
  vte_measure_stat: null,
  y_axis_value: null,
  breakdown_by: null,
  drug_info:  [{substance_id: null, form_id: null, product_id: null}],
  drug_meta: null,
  admins_enabled: false,
  prn_include: false,
  drug_view_option: null,
  drug_view_attr: null,
  patient_id: null
});

export const standard_filter_info_json = JSON.stringify({
  available_filters: {},
  silent_filters: {},
  filter_actions: {},
  default_filters: JSON.parse(filter_values),
  filters: JSON.parse(filter_values),
  filter_history: [],
  shared_filters: {},
  using_defaults: true,
  filter_version: 0,
  first_run: true,
  dirty: false,
  fetched: false,
  fetching: false,
  drug_group_options: {
    hierarchy: {},
    mapping: {},
  },
  ward_group_options: {
    hierarchy: {},
    mapping: {},
  },
  drug_custom_classifiers: null,
  drug_classifier_saves: {},
  ward_options: {},
  admin_reason_options: {},
  admin_reason_group_options: {},
  specialty_options: {},
  epm_options: {},
  route_options: {},
  form_options: {},
  impact_indicator_options: {},
  meta: {},
  group_options: {},
  chart_type_options: {},
  y_axis_options: {},
  date_options: {},
  info_box: {show: false},
  strength_options: {}
});

export const dashboard = {
  dashboards: {
  
  
    
      
        usage_overview: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        usage_admin_counts: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        usage_admin_counts_over_time: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        usage_ward: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        usage_ward_drugs: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        usage_ward_drugs_table: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        usage_prediction: JSON.parse(standard_filter_info_json),
        
      

      

    
      
        usage_prediction_breakdown_locations: JSON.parse(standard_filter_info_json),
        
      
    

    

    
      
        usage_admins_list: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        usage_patient_admins: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        usage_admins_summary: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        antibiotics_active_orders: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        antibiotics_dot: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        antibiotics_ddd: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        antibiotics_order_length: JSON.parse(standard_filter_info_json),
        
      

      
        
          antibiotics_order_length_breakdown: JSON.parse(standard_filter_info_json),
          
        
      

    

    

    
      
        antibiotics_order_specialty: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        antibiotics_admin_specialty: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        antibiotics_route: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        antibiotics_shortage: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        prescribing_active_prescriptions: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        prescribing_opioids: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        omitted_doses_league_table: JSON.parse(standard_filter_info_json),
        
      

      
        
          omitted_doses_drug_reason: JSON.parse(standard_filter_info_json),
          
        
      
        
          omitted_doses_administrator_reason: JSON.parse(standard_filter_info_json),
          
        
      

    

    

    
      
        omitted_doses_early_late_doses_league_table: JSON.parse(standard_filter_info_json),
        
      

      
        
          omitted_doses_drug_time_sensitivity: JSON.parse(standard_filter_info_json),
          
        
      
        
          omitted_doses_early_late_trends: JSON.parse(standard_filter_info_json),
          
        
      
        
          omitted_doses_hourly_time_sensitivity: JSON.parse(standard_filter_info_json),
          
        
      

    

    

    
      

      

    

    

    
      
        omitted_doses_overview: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        omitted_doses_ward_view: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        omitted_doses_admin_reasons: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        omitted_doses_admin_reasons_ward_compare: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        anticholinergics_cognition: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        anticholinergics_overview: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        anticholinergics_breakdown: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        parkinsons_counts: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        parkinsons_delayed_counts: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        parkinsons_delayed_timeline: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        parkinsons_early_late_doses_league_table: JSON.parse(standard_filter_info_json),
        
      

      
        
          parkinsons_drug_time_sensitivity: JSON.parse(standard_filter_info_json),
          
        
      
        
          parkinsons_early_late_trends: JSON.parse(standard_filter_info_json),
          
        
      
        
          parkinsons_hourly_time_sensitivity: JSON.parse(standard_filter_info_json),
          
        
      

    

    

    
      

      

    

    

    
  
    
      
        admin_burden_active_counts: JSON.parse(standard_filter_info_json),
        
      

      

    
      
        admin_burden_active_patients: JSON.parse(standard_filter_info_json),
        
      
    

    

    
      
        admin_burden_overview: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        admin_burden_counts: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        admin_burden_scores: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        hospital_admissions: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        hospital_bed_days: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        stock_history: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        stock_shortages: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        stock_lookup: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        safety_active_overview: JSON.parse(standard_filter_info_json),
        
      

      

    
      
        safety_location_overview: JSON.parse(standard_filter_info_json),
        
      
    

    

    
      
        safety_ward_view: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        safety_covert_medications: JSON.parse(standard_filter_info_json),
        
      

      
        
          patient_omitted_drug_breakdown: JSON.parse(standard_filter_info_json),
          
        
      

    

    

    
  
    
      
        vte_active_patient_indicators: JSON.parse(standard_filter_info_json),
        
      

      
        
      

    

    

    
  
    
      
        performance_ward: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        performance_ward_checks: JSON.parse(standard_filter_info_json),
        
      

      
        
          performance_ward_check_breakdown: JSON.parse(standard_filter_info_json),
          
        
      

    

    

    
  
    
      
        opioids_admins_league_table: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        monitoring_vte: JSON.parse(standard_filter_info_json),
        
      

      

    

    
      
        monitoring_active_vte_risk: JSON.parse(standard_filter_info_json),
        
      
    
      
        monitoring_active_vte_risk_patient: JSON.parse(standard_filter_info_json),
        
      
    
      
        monitoring_vte_risk_history: JSON.parse(standard_filter_info_json),
        
      
    
      
        monitoring_vte_risk_history_patient: JSON.parse(standard_filter_info_json),
        
      
    

    
      
        monitoring_allergy: JSON.parse(standard_filter_info_json),
        
      

      

    

    
      
        monitoring_allergy_verifications: JSON.parse(standard_filter_info_json),
        
      
    
      
        monitoring_allergy_verifications_patient: JSON.parse(standard_filter_info_json),
        
      
    
      
        monitoring_allergy_verifications_history: JSON.parse(standard_filter_info_json),
        
      
    
      
        monitoring_allergy_verifications_history_patient: JSON.parse(standard_filter_info_json),
        
      
    

    
      
        monitoring_oxygen: JSON.parse(standard_filter_info_json),
        
      

      

    

    
      
        monitoring_active_oxygen: JSON.parse(standard_filter_info_json),
        
      
    
      
        monitoring_active_oxygen_patient: JSON.parse(standard_filter_info_json),
        
      
    
      
        monitoring_oxygen_history: JSON.parse(standard_filter_info_json),
        
      
    
      
        monitoring_oxygen_history_patient: JSON.parse(standard_filter_info_json),
        
      
    

    
      
        monitoring_patient_usage_stats: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        probe_active_users: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
      
        probe_dashboard_popularity: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        pharmacy_monitoring_summary: JSON.parse(standard_filter_info_json),
        
      

      
        
      
        
          patient_unvalidated_drug_breakdown: JSON.parse(standard_filter_info_json),
          
        
      

    

    

    
  
    
      
        controlled_ward_history: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
    
      
        insulins_delayed_counts: JSON.parse(standard_filter_info_json),
        
      

      

    

    

    
  
  },
  filter_cache: {
    date_range_start: null,
    date_range_end: null,
    ward_group_id: null,
    ward_group_ids: null,
    ward_ids: null,
    ward_grouping_type: null,
    date_grouping: null,
    age_range_start: null,
    age_range_end: null,
    sex_id: null,
    version: 0,
  },
  drug_id_cache: {}, // VTM
  drug_product_cache: {}, // VMP
  ward_id_cache: {},
  patient_id_cache: {},
  group_options_id_cache:  {},
  drug_cache: {}
};