import {DisplaySetting} from "../../api";

const actions = {
  async saveDisplaySettings({commit}, {data}) {
    return DisplaySetting.saveDisplaySettings(data);
  },
};

const mutations = {};

export default {
  namespace: true,
  actions,
  mutations
}