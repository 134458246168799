import qs from "qs";

export const GROUP_LIST_TYPES = ['drug', 'ward', 'admin_reason'];

const ENABLING_PARAMS = {
  bed_days_count: 'bed_days_enabled',
  average_interval: 'average_enabled'
}
const PARAM_FILTERS = {
  admin_reason_ids: {
    filter: 'makeObject'
  },
  admin_reason_id: {
    filter: 'makeObject'
  },
  drug_info: {
    filter: 'filterZeros'
  },
  drug_ids: {
    filter: 'makeObject'
  }
}

export const ApiHelper = {

  makeObject(value, _data) {
    const dataIn = Array.isArray(value) ? value : [value];
    let out = [];
    dataIn.forEach(item => out.push((typeof item === 'string' ? JSON.parse(item) : item)));

    return Array.isArray(value) ? out : out[0];
  },

  getParams(dashboard, dashboard_info) {
    let params = {};

    let validParams = {...dashboard_info.available_filters, ...dashboard_info.silent_filters}
    for (let [k, v] of Object.entries(validParams)) {
      if (ENABLING_PARAMS[k] && !dashboard_info.filters[ENABLING_PARAMS[k]]) {
        continue;
      }

      let proceed = true;
      for (let t of GROUP_LIST_TYPES) {
        if ((this.isGroupFilter(t, k) && dashboard_info.filters[`${t}_grouping_type`] !== 'existing') ||
          (this.isListFilter(t, k) && dashboard_info.filters[`${t}_grouping_type`] !== 'custom')) {
          proceed = false;
          break;
        }
      }

      if (!proceed) {
        continue;
      }

      if (PARAM_FILTERS[k] && dashboard_info.filters[k]) {
        params[k] = this[PARAM_FILTERS[k].filter](dashboard_info.filters[k], dashboard_info.filters);
        continue;
      }

      if (!dashboard_info.filters[k]) {
        continue;
      }

      params[k] = dashboard_info.filters[k]
    }

    params['dashboard'] = dashboard;
    // params['history_id'] = Helpers.methods.generateId();
    return params;
  },

  filterZeros(value, _data) {
    const dataOut = value.slice();
    value.forEach((item, i) => {
      const productIsAll = _.isEmpty(_.xor(['0'], item.product_id))
      if (item.form_id === '0' && productIsAll) {
        dataOut[i] = {substance_id: item.substance_id}
      } else if (productIsAll) {
        dataOut[i] = {substance_id: item.substance_id, form_id: item.form_id}
      }
    });
    return dataOut;
  },

  isGroupFilter(type, k) {
    return k === `${type}_group_id` || k === `${type}_group_ids` || k === `${type}_group_grouping_ids`;
  },

  isListFilter(type, k) {
    return k === `${type}_ids` || k === `${type}_id`;
  },

  getParamsString(dashboard, dashboard_info) {
    return qs.stringify(this.getParams(dashboard, dashboard_info), {
      arrayFormat: 'brackets',
      skipNulls: true,
      encode: false
    });
  }
};