export const SET_FILTERS = 'SET_FILTERS';
export const SET_FILTER = 'SET_FILTER';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_DASHBOARD_INFO = 'SET_DASHBOARD_INFO';
export const SET_DRUG_OPTIONS_CACHE = 'SET_DRUG_OPTIONS_CACHE';
export const SET_DRUG_CACHE = 'SET_DRUG_CACHE';
export const SET_WARD_OPTIONS_CACHE = 'SET_WARD_OPTIONS_CACHE';
export const SET_PATIENT_OPTIONS_CACHE = 'SET_PATIENT_OPTIONS_CACHE';
export const SET_DRUG_GROUP_DATA = 'SET_DRUG_GROUP_DATA';
export const SET_DIRTY = 'SET_DIRTY';
export const FILTER_HISTORY_ADD = 'FILTER_HISTORY_ADD';
export const FILTER_HISTORY_UPDATE = 'FILTER_HISTORY_UPDATE';
export const HANDLE_SHARED_FILTERS = 'HANDLE_SHARED_FILTERS';
export const UPDATE_FILTER_CACHE = 'UPDATE_FILTER_CACHE';
export const SET_GROUP_OPTIONS_ID_CACHE = 'SET_GROUP_OPTIONS_ID_CACHE';
export const RESET_DASHBOARD_INFO = 'RESET_DASHBOARD_INFO';
export const SET_FETCHED = 'SET_FETCHED';
