import Axios from 'axios';
import {store} from '../../store/';

export const Hospital = {
  fetchHospitals() {
    return Axios.get('/hospitals')
  },

  addHospital(data) {
    return Axios.post('/hospitals', {user: data});
  },

  deleteHospital(userRef){
    return Axios.delete(`/hospitals/${userRef}`);
  },

  resetHospitalData(systemType) {
    return Axios.delete(`/hospitals/${store.state.user.active_user.ref}/data/reset/${systemType}`);
  },

  exportUsers() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/export_users`)
  },

  rebuildHospitalData(names) {
    const data = {
      names: names
    };
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/data/rebuild`, data)
  },

  vacuumHospitalData(vacType) {
    const data = {
      tenant_data: {table_type: vacType}
    };
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/data/vacuum`, data)
  },

  recalculateHospitalData(name) {
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/data/recalculate/${name}`)
  },

  fetchDataLogs() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/data_logs`)
  },

  deleteDataLog(dataLog) {
    return Axios.delete(`/hospitals/${store.state.user.active_user.ref}/data_logs/${dataLog.id}`)
  },

  rerunDataLogDenorm(dataLog, table) {
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/data_logs/${dataLog.id}/rerun_denorm/${table}`)
  },

  fetchExtractors() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/extractors`)
  },

  fetchExtractor(ref) {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/extractors/${ref}`)
  },

  saveExtractor(extractor) {
    return Axios.put(`/hospitals/${store.state.user.active_user.ref}/extractors/${extractor.ref}`, {extractor: extractor})
  },

  saveExtractorAndPublish(extractor) {
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/extractors/${extractor.ref}/publish`, {extractor: extractor})
  },

  fetchDataOperations() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/data_operations`)
  },

  runDataOperation(operation, action) {
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/data_operations/${operation.ref}/action/${action}`)
  },

  deleteNotification(notification) {
    return Axios.delete(`/hospitals/${store.state.user.active_user.ref}/notifications/${notification.id}`)
  },

  fetchErrors(page) {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/errors/page/${page}`)
  },

  fetchErrorInfo() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/errors/info`)
  },

  deleteError(error) {
    return Axios.delete(`/hospitals/${store.state.user.active_user.ref}/errors/${error.id}`)
  },

  deleteErrorLikeThis(error) {
    return Axios.delete(`/hospitals/${store.state.user.active_user.ref}/errors/delete_like_this`, {error: error})
  },
};