export default {
  install(Vue, { store }){
    if (!store) {
      throw new Error("Please provide vuex store.");
    }

    Vue.prototype.$linkgen = (link) =>{
      if(store.state.user.current_user.ref === store.state.user.active_user.ref &&
        store.state.user.current_user.username !== 'admin') {
        return link;
      }
      return `/hospitals/${store.state.user.active_user.username}/${store.state.user.active_user.ref}${link}`
    }

    Vue.prototype.$roleVisibility = (uiViewName) => {

    }
  }
};