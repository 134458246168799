const state = {
  sidebar: {
    static: true,
    close: true
  }
};

const actions = {
  switchSidebar: ({commit}) => {
    commit('SET_STATIC_SIDEBAR');
  },
  toggleSidebar: ({commit}) => {
    commit('SET_SIDEBAR_TOGGLE');
  },
};

const mutations = {
  SET_STATIC_SIDEBAR: (state) => {
    state.sidebar.static = !state.sidebar.static
  },
  SET_SIDEBAR_TOGGLE: (state) => {
    state.sidebar.close = !state.sidebar.close
  }
};

export default {
  namespace: true,
  state,
  actions,
  mutations
}
