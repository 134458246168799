import {Export} from "../../api";
import {ADD_EXPORT, SET_EXPORTS} from "./types/export_types";
import Vue from "vue";

import {active_user} from './state/user';

const state = active_user;

const actions = {
  async fetchExports({commit}) {
    const response = await Export.fetchExports();
    commit(SET_EXPORTS, {exports: response.data})
  },

  async createExport({commit}) {
    const response = await Export.createExport();
    commit(ADD_EXPORT, {newExport: response.data})
  },

  async downloadExport({commit}, {id}) {
    const response = await Export.downloadExport(id);
    return response.data;
  }
};

const mutations = {
  [SET_EXPORTS](state, {exports}) {
    Vue.set(state, 'exports', exports);
  },

  [ADD_EXPORT](state, {newExport}) {
    state.exports.push(newExport);
  }
};

export default {
  namespace: true,
  state,
  actions,
  mutations
}