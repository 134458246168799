import Vue from 'vue'
import Router from 'vue-router'

import {store} from '../store'

Vue.use(Router);

const DashboardView = () => import('../views/user/DashboardView.vue');

const AdminManageHospitalView = () => import('../views/admin/AdminManageHospitalView');
const AdminUserView = () => import('../views/admin/hospital_settings/AdminUsersView');
const AdminDataView = () => import('../views/admin/hospital_settings/AdminDataView.vue');
const AdminExtractorView = () => import('../views/admin/hospital_settings/AdminExtractorsView');
const ExtractorToolGeneralTabView = ()=> import('../views/admin/hospital_settings/extractors/ExtractorToolGeneralTabView')

const LoginView = () => import('../views/user/auth/LoginView.vue');
const ForgottenPasswordView = () => import('../views/user/auth/ForgottenPasswordView.vue');
const RecoverPasswordView = () => import('../views/user/auth/RecoverPasswordView.vue');
const UpdatePasswordView = () => import('../views/user/auth/UpdatePasswordView.vue');
const OnboardingWelcomeView = () => import('../views/user/auth/OnboardingWelcomeView.vue');
const DrugMappingView = () => import('../views/admin/drug_mappings/DrugMappingView.vue');

const AdminView = () => import('../views/admin/AdminView.vue');
const ManageHospitalsView = () => import('../views/admin/hospital_management/ManageHospitalsView.vue');
const DmdInfoView = () => import('../views/admin/dmd/DmdInfoView.vue');

const ScreenView = () => import('../views/user/dashboards/ScreenView.vue.erb');

const ManageDrugGroupsView = () => import('../views/user/settings/user/ManageDrugGroupsView');
const FilterSettingsView = () => import('../views/user/settings/user/FilterSettingsView.vue');
const ExportView = () => import('../views/user/settings/user/ExportView');
const DisplaySettingsView = () => import('../views/user/settings/user/DisplaySettingsView')

const UserSettingsView = () => import('../views/user/settings/UserSettingsView')
const HospitalSettingsView = () => import('../views/user/settings/HospitalSettingsView')
const AdminBurdenSettingsView = () => import('../views/user/settings/hospital/AdminBurdenSettingsView')
const WardCheckSettingsView = () => import('../views/user/settings/hospital/WardCheckSettingsView')

export function createRouter(i18n) {

  const roleRedirectRoutes = {
    '/': {
      admin: '/admin'
    }
  };

  const ADMIN_BASE = '/hospitals/:username/:userRef';

  const router = new Router({
    mode: 'history',
    fallback: false,
    scrollBehavior: () => ({y: 0}),
    routes: [
      {path: '/login', component: LoginView},
      {path: '/forgotten_password', component: ForgottenPasswordView},
      {path: '/password_reset/:token', component: RecoverPasswordView, props: true},

      {path: '/welcome/:username/:token',  component: OnboardingWelcomeView, props: true},
      {path: '/update_password',  component: UpdatePasswordView, meta: {auth: true}},
      {path: '/dmd', component: DmdInfoView, meta: {auth: true, roles: ['admin']}},
      {path: '/drug_mappings', component: DrugMappingView, meta: {auth: true, roles: ['admin', 'super_user']}},

      {path: '/admin/hospitals', component: ManageHospitalsView, meta: {auth: true, roles: ['admin']}},
      {path: `${ADMIN_BASE}/manage`, component: AdminManageHospitalView, props: true, meta: {auth: true, roles: ['admin']}},

      {path: `${ADMIN_BASE}/drug_mappings`, component: DrugMappingView, props: true, meta: {auth: true, roles: ['admin']}},

      {path: `${ADMIN_BASE}/manage/users`, component: AdminUserView, props: true, meta: {auth: true, roles: ['admin']}},
      {path: `${ADMIN_BASE}/manage/data/:tab?/(page/:page)?`, component: AdminDataView, props: true, meta: {auth: true, roles: ['admin']}},
      {path: `${ADMIN_BASE}/manage/extractors`, component: AdminExtractorView, meta: {auth: true, roles: ['admin']}},
      {path: `${ADMIN_BASE}/manage/extractors/:id/:tab?`, component: ExtractorToolGeneralTabView, meta: {auth: true, roles: ['admin']}},

      {path: `${ADMIN_BASE}/screens/:screen`, component: ScreenView, props: true, meta: {auth: true, roles: ['admin']}},
      {path: `${ADMIN_BASE}/screens/:screen/:dashboard`, component: ScreenView, props: true, meta: {auth: true, roles: ['admin']}},
      {path: `${ADMIN_BASE}`, component: DashboardView, props: true, meta: {auth: true, roles: ['admin']}},
      {path: `${ADMIN_BASE}/groups/drugs`, component: ManageDrugGroupsView, meta: {auth: true, roles: ['admin']}},

      {path: '/', component: DashboardView, meta: {auth: true, role_redirect: true}},
      {path: `/screens/:screen`, component: ScreenView, props: true, meta: {auth: true}},
      {path: `/screens/:screen/:dashboard`, component: ScreenView, props: true, meta: {auth: true}},

      {path: '/groups/drugs', component: ManageDrugGroupsView, meta: {auth: true, role_redirect: true}},

      {path: '/settings/user', component: UserSettingsView, meta: {auth: true, role_redirect: true}},
      {path: '/settings/filters', component: FilterSettingsView, meta: {auth: true, role_redirect: true}},
      {path: '/settings/exports', component: ExportView, meta: {auth: true, role_redirect: true}},
      {path: '/settings/display', component: DisplaySettingsView, meta: {auth: true, role_redirect: true}},

      {path: '/settings/hospital', component: HospitalSettingsView, meta: {auth: true, role_redirect: true, roles: ['super_user']}},
      {path: '/settings/hospital/admin_burden', component: AdminBurdenSettingsView, meta: {auth: true, role_redirect: true, roles: ['super_user']}},
      {path: '/settings/hospital/ward_check', component: WardCheckSettingsView, meta: {auth: true, role_redirect: true, roles: ['super_user']}},

      {path: '/admin', component: AdminView,  meta: {auth: true, roles: ['admin']}},
    ]
  });

  router.beforeEach((to, from, next) => {
    if(_.isEmpty(to.matched)) {
      next('/')
    }

    const authRequired = to.matched.some((route) => route.meta.auth);
    const roleRedirect = to.matched.some((route) => route.meta.role_redirect);

    let matchedRoleRedirects = null;
    let path = to.fullPath;

    const rolesRefs = _.map(store.state.user.current_user.role_ids, (id) => _.find(store.state.user.current_user.roles_available, (role) => role.id === id).ref);

    if(store.state.user.current_user.username && roleRedirectRoutes[path]) {
      matchedRoleRedirects = _.intersection(rolesRefs,  Object.keys(roleRedirectRoutes[path]));
    }

    let redir = null;
    if(matchedRoleRedirects && roleRedirect) {
      const first = _.find(matchedRoleRedirects, (role) => role in roleRedirectRoutes[path]);
      redir = first ? roleRedirectRoutes[path][first] : null
    }

    if(to.params["username"] && store.state.user.active_user.username !== to.params["username"]) {
      store.dispatch('resetQueryData');
    }

    store.state.user.active_user.ref = to.params["userRef"] || store.state.user.current_user.ref;
    store.state.user.active_user.username = to.params["username"] || store.state.user.current_user.username;

    if(!authRequired) {
      redir ? next(redir) : next();
      return
    }

    if(authRequired && !store.state.user.current_user.username) {
      store.state.user.current_user.last_url = path;
      next('/login');
      return
    }

    const roleRequired = to.matched.some((route) => !_.isEmpty(route.meta.roles));
    let hasRole = true;
    if(roleRequired) {
      hasRole = to.matched.some((route) => !_.isEmpty(
        _.intersection(rolesRefs,route.meta.roles))
      )
    }

    if(authRequired && hasRole) {
      if(store.state.user.current_user.last_url) {
        const url = store.state.user.current_user.last_url;
        store.state.user.current_user.last_url = null;
        next(url);
        return
      }
      redir ? next(redir) : next();
    } else {
      next('/forbidden')
    }
  });

  return router;
}