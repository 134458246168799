import Vue from 'vue'
import {SET_DRUG_PRODUCT_INFO, SET_DRUG_PRODUCT_INFO_FROM_SEARCH} from "./types/data_cache_types";
import {DrugInfo} from "../../api";

const state = {
  vtm_to_name: {},
  vmp_to_name: {},
};

const actions = {
  addFullDrugProductInfo({commit}, {data}) {
    commit(SET_DRUG_PRODUCT_INFO_FROM_SEARCH, data)
  },

  async populateDrugFullProductCache({commit}, {data}) {
    if (_.isEmpty(data)) {
      return;
    }
    const drugData = await DrugInfo.drugProductFromIds(data);
    commit(SET_DRUG_PRODUCT_INFO, drugData.data);
  },
};

const mutations = {
  [SET_DRUG_PRODUCT_INFO]: (state, data) => {
    data.forEach((item) => { state.vmp_to_name[item.vmp_id] = item.vmp_name });
  },

  [SET_DRUG_PRODUCT_INFO_FROM_SEARCH]: (state, data) => {
    data.forEach((item) => {
      item.vmps.forEach((vmp) => {
        state.vmp_to_name[vmp.vmp_id] = vmp.vmp_name
      })
    });
  }
};

export default {
  namespace: true,
  state,
  actions,
  mutations
}