import {Filter} from "../../api";

const actions = {
  async saveFilterSettings({commit}, {data}) {
    return Filter.saveFilterSettings(data);
  },
};

const mutations = {};

export default {
  namespace: true,
  // state,
  actions,
  mutations
}