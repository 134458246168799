/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require.context('../images', true);

import 'bootstrap/dist/js/bootstrap.js'
import 'tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.js'

import 'codemirror/mode/yaml/yaml'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/dracula.css'

import 'select2/dist/js/select2.full'
import 'select2/dist/css/select2.css'

import 'vue-resize/dist/vue-resize.css'

import 'bootstrap-vue/dist/bootstrap-vue.css'

// import 'lib/select2/select2.js'
// import 'lib/select2/select2.css'

import 'plugins/hierarchy-select/src/hierarchy-select.js'
import 'plugins/hierarchy-select/src/hierarchy-select.scss'

import 'vue-multiselect/dist/vue-multiselect.min.css'

import 'clipboard/dist/clipboard.js'

import 'ion-rangeslider/js/ion.rangeSlider'
import 'ion-rangeslider/css/ion.rangeSlider.css'

import 'datatables.net/js/jquery.dataTables.js'
import 'css/application'
import 'vue/main.js'

import 'channels/triscribe_channel'