import Vue from 'vue'
import {DrugMapping, DrugInfo} from '../../api'
import {map} from 'lodash'
import {handleDrugInfo} from './helpers/drug_helper'

const state = {
    drug_mappings: {
        data: null
    },
    drug_info: {
        data: {}
    }
};

const actions = {
    remapHospitalData: ({commit}, {hospitalRef, name, mapping_type}) => {
        return DrugMapping.remapHospitalData(hospitalRef, name, mapping_type).then((response) => {
            commit('SET_DATA_STATUS', response.data)
        }).catch((e) => {
            commit('SET_DATA_STATUS', {status: 'errored', error: e, updated_at: null})
        })
    },

    updateDrugMapping: ({commit}, {drug}) => {
        return DrugMapping.updateDrugMapping(drug).then((response) => {
            commit('SET_DRUG_DATA', {drug: drug, data: response.data});
            return response.data
        }).catch((e) => {
            return Promise.reject(e.response.data["base"][0]);
        })
    },

    verifyDrugMapping: ({commit}, {drug}) => {
        return DrugMapping.verifyDrugMapping(drug).then(response => {
            commit('SET_DRUG_DATA', response.data);
            return response.data
        }).catch((e) => {
            return e.response.data["base"][0];
        })
    },

    getDrugMappings: ({commit}, {page, perPage, status}) => {
        return DrugMapping.getDrugMappings(page, perPage, status).then(response => {
            response.data.drugs.forEach((item) => {
                item.correction_type = 'vmp';
                item.correction_id = null;
            });

            commit('SET_DRUG_MAPPINGS', response.data);
            return response.data
        }).catch((e) => {
            return e;
        })
    },

    drugMappingCorrection: ({commit}, {drug}) => {
        return DrugMapping.drugMappingCorrection(drug)
    },

    drugInfo: ({commit}) => {
        return DrugInfo.drugInfo().then(response => {
            handleDrugInfo(response, commit)
        })
    },

    resetDmd: ({commit}) => {
        return DrugInfo.resetDmd().then(response => {
            handleDrugInfo(response, commit)
        })
    },

    resetDmdSup: ({commit}) => {
        return DrugInfo.resetDmdSup().then(response => {
            handleDrugInfo(response, commit)
        })
    },

    resetSnomed: ({commit}) => {
        return DrugInfo.resetSnomed().then(response => {
            handleDrugInfo(response, commit)
        })
    },
    updateGroups: ({commit}) => {
        return DrugInfo.updateGroups().then(response => {
            handleDrugInfo(response, commit)
        })
    },
    fetchDmd: ({commit}) => {
        return DrugInfo.regenerateDmd().then(response => {
            handleDrugInfo(response, commit)
        })
    },

    fetchDmdSup: ({commit}) => {
        return DrugInfo.regenerateDmdSup().then(response => {
            handleDrugInfo(response, commit)
        })
    },

    fetchSnomed: ({commit}) => {
        return DrugInfo.regenerateSnomed().then(response => {
            handleDrugInfo(response, commit)
        })
    },

    regenerateDict: () => {
        return DrugInfo.regenerateDict()
    }
};

const mutations = {
    SET_DRUG_MAPPINGS: (state, data) => {
        Vue.set(state.drug_mappings, 'data', data.drugs);
        Vue.set(state.drug_mappings, 'total', data.total);
    },
    SET_DRUG_INFO: (state, data) => {
        Vue.set(state.drug_info, 'data', data)
    },
    SET_DRUG_DATA: (state, {drug, data}) => {
        Vue.set(drug, 'mapped_drug_description', data.mapped_drug_description);
        Vue.set(drug, 'vmp_id', data.vmp_id);
        Vue.set(drug, 'amp_id', data.amp_id);
        Vue.set(drug, 'vtm_id', data.vtm_id);
        Vue.set(drug, 'trade_family_id', data.trade_family_id);
        Vue.set(drug, 'mapping_type', data.mapping_type);
        Vue.set(drug, 'status', data.status);
        Vue.set(drug, 'correction_type', 'vtm');
        Vue.set(drug, 'correction_id', null);
    }
};

export default {
    namespace: true,
    state,
    actions,
    mutations
}