<template>
  <ul class="sidebar-nav">
    <nav-link :top-nav-active="isHome"
              top-nav-icon="fi flaticon-home"
              top-nav-link="/admin"
              :top-nav-text="$t('home')"></nav-link>

    <nav-link :top-nav-active="isUserAdmin"
              top-nav-icon="fi flaticon-user"
              :top-nav-text="$t('user_admin')"
              :child-links="menu_options.user_admin"></nav-link>

    <nav-link :top-nav-active="isDataAdmin"
              top-nav-icon="fi flaticon-database"
              :top-nav-text="$t('data_admin')"
              :child-links="menu_options.data_admin"></nav-link>
  </ul>
</template>

<script>
  import NavLink from '../../layout/NavLink.vue'

  export default {
    data () {
      return {
        current_user: this.$store.state.user.current_user,
        sidebar: this.$store.state.view_model.sidebar,

        menu_options: {
          user_admin: [{label: this.$i18n.t('manage_hospitals'), to: '/hospitals/manage'}],
          data_admin: [{label: this.$i18n.t('drug_mappings'), to: '/drug_mappings'},
                       {label: this.$i18n.t('dmd_snomed'), to: '/dmd'}]
        }
      }
    },
    props: ['userRef'],
    components: {NavLink},
    computed: {
      isHome() {
        return this.$route.path == '/admin'
      },
      isDataAdmin() {
        return this.$route.path == '/drug_mappings' || this.$route.path == '/dmd'
      },
      isUserAdmin() {
        return this.$route.path == '/hospitals/manage' || /\/hospitals\/\w+\//.test(this.$route.path)
      }
    }
  }
</script>

<style>

</style>