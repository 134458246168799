<template>
  <li :class="[{active: topNavActive}, 'headerLink']">
    <a v-if='childLinks' @click.prevent="toggleMenu()">
      <span class="icon"><i :class="topNavIcon"></i></span>
      {{ topNavText }}
        <i :class="[{'toggle-rot': !opened}, 'toggle', 'fa', 'fa-angle-down']"></i>
    </a>

    <router-link v-if="!childLinks" :to="topNavLink">
      <span class="icon"><i :class="topNavIcon"></i></span>
      {{ topNavText }}
    </router-link>

    <div :class="[{opened: toggle}, 'panel']">
      <ul v-if="childLinks">
        <li v-for="link in childLinks" :class="{active: $route.path == link.to}">
          <router-link :to="link.to">
            {{ link.label }}
          </router-link>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
  import Helpers from '../../mixins/helpers';

  export default {
    data() {
      return {
        id: null,
        opened: false,
        sidebar: this.$store.state.view_model.sidebar
      }
    },
    props: ['top-nav-active', 'collapse', 'child-links', 'top-nav-icon', 'top-nav-link', 'top-nav-text'],
    mixins: [Helpers],
    created() {
      this.id = this.generateId()
    },
    methods: {
      toggleMenu() {
        this.opened = !this.opened
      }
    },
    computed: {
      toggle() {
        if(!this.sidebar.static) {
          this.opened = false;
        }
        return this.opened
      }
    }
  }
</script>

<style>
  .panel {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    background-color: white;
    max-height: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .panel.opened {
    max-height: 150px;
    overflow: hidden;
  }
  .panel a {
    line-height: 20px !important;
  }
  .panel a.router-link-active {
    font-weight: 600;
    color: #6c757d;
  }
  .panel ul {
    background: #f9fafe;
    padding: 1rem;
  }
  .panel ul li {
    list-style: none;
  }
  .panel ul a {
    padding: 10px 20px 10px 26px;
    font-size: 0.9rem;
  }
  .panel ul a:hover {
    background-color: #f9fafe;
  }
  .toggle-rot {
    transform: rotate(90deg);
  }
</style>