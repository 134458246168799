import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import query from './modules/query.js.erb'
import drug_mapping from './modules/drug_mapping'
import view_model from './modules/view_model'
import dashboard from './modules/dashboard'
import group from './modules/group'
import filter from './modules/filter'
import history from './modules/history'
import data_cache from './modules/data_cache'
import exports from './modules/export'
import hospital_setting from './modules/hospital_setting'
import display_setting from './modules/display_setting'

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user, query, drug_mapping, view_model, dashboard, group, history, data_cache, filter, exports, hospital_setting,
    display_setting
  }
});