import Axios from "axios";
import {store} from '../../store/';

export const Export = {
  fetchExports() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/exports`)
  },

  createExport() {
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/exports`)
  },

  downloadExport(id) {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/exports/${id}/download`)
  }
};