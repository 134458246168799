import Axios from "axios";
import {store} from '../../store/';

export const Group = {
  create_group(data) {
    let out = {group: data};
    if(data.dashboard) {
      out.dashboard = data.dashboard;
    }
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/groups`, out)
  },

  fetch_user_drugs_groups() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/groups`)
  },

  delete_group(group) {
    return Axios.delete(`/hospitals/${store.state.user.active_user.ref}/groups/${group.id}`)
  },

  fetch_ward_groups() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/groups/wards`)
  }
};