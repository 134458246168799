import Vue from 'vue'
import {history} from './state/history';

import {
 ADD_TO_HISTORY, SET_HISTORY_DATA
} from './types/history_types';

import {store} from "../index";

const state = history;

const actions = {
  addToHistory(dashboard, filters) {

  }
};

const mutations = {
  [ADD_TO_HISTORY](state, {dashboard, id, filters, title}) {
    state.data[id] = ({id: id, dashboard: dashboard, filters: filters, title: title, state: 'loading'})
  },

  [SET_HISTORY_DATA](state, {id, data, filters}) {
    state.data[id].data = data;

  }
};


export default {
  namespace: true,
  state,
  actions,
  mutations
}