export function default_return_data_obj(value = {}) {
  return JSON.stringify({data: value, filters: {date_grouping: null}, meta: {}});
}

export function default_return_data_array(itemCount, value = []) {
  let data = [];
  _.times(itemCount, () => data.push(value));
  return JSON.stringify({data: data, filters: {date_grouping: null}, meta: {}});
}

export default {default_return_data_obj, default_return_data_array}