import {tenant_data} from './tenant_data.js';

const userObj = {
  last_url: null,
  username: null,
  email: null,
  role_ids: [],
  roles: [],
  roles_available: [],
  ref: null,
  hospital_ref: null,
  hospital_name: null,
  tenant: {
    users: {},
    tenant_data: tenant_data
  },
  ui: {},
  filter_setting: {},
  exports: {},
  api_token: null
}

export const user = {...userObj};
export const active_user = {...userObj};
export const current_user = {...userObj};

export const user_view_model = {
  hospital: {
    deleted: null
  }
};

// export const users = {/* [username: string]: User */};
export const hospitals = {/* [username: string]: User */};

export const extractors = {
  extractors: [],
  extractor: {
    name: null,
    device_id: null,
    config: null,
    ref: null,
    id: null,
    current_config_version: null,
    notifications: []
  }
};

export const data_operations = {
  data_operations: [],
  data_operation: {
    date_increments: null,
    current_start_date: null,
    current_stop_date: null,
    ref: null,
    denormalised_table: null,
    temp_table_name: null,
    replaced_table_name: null,
    status: null,
    type: null,
    name: null,
  }
};

export const data_logs = {
  data_logs: []
};

export const errors = {
  errors: [],
  info: {
    total: 0
  }
};