import Axios from 'axios';
import {store} from '../../store/';

export const User = {
  fetchUsers() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/users`);
  },

  fetchActiveUser() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/users/${store.state.user.active_user.ref}`)
  },

  fetchUser(userRef) {
    return Axios.get(`/users/${userRef}`)
  },

  addUser(data) {
    data.tenant_id = store.state.user.active_user.tenant_id;
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/users`, {user: data});
  },

  updateUser(user) {
    return Axios.put(`/hospitals/${store.state.user.active_user.ref}/users/${user.ref}`, {user: user});
  },

  deleteUser(userRef){
    return Axios.delete(`/hospitals/${store.state.user.active_user.ref}/users/${userRef}`);
  },

  login(username, password, rememberMe) {
    return Axios.post('/users/sign_in.json',
      {user: {login: username, password: password, remember_me: rememberMe}})
  },

  logout() {
    return Axios.delete('/users/sign_out.json')
  },

  forcePasswordChangeUrl(user) {
    return Axios.post(`/users/${user.ref}/force_password_change_url`)
  },

  forcePasswordChange(token, password) {
    return Axios.post('/users/force_password_change', {user: {token: token, password: password}})
  },

  forcePasswordChangeCheck(token) {
    return Axios.get(`/users/force_password_change/${token}`)
  },

  forgottenPassword(login) {
    return Axios.post('/users/forgotten_password/', {user: {login: login}})
  },

  resetPassword(token, password) {
    return Axios.post('/users/reset_password', {user: {password: password, reset_password_token: token}})
  },

  changePassword(existing_password, new_password) {
    return Axios.post(`/users/${store.state.user.active_user.ref}/password`,
                      {user: {existing_password: existing_password, new_password: new_password}})
  },

  saveTenantData(tenant_data) {
    return Axios.put(`/hospitals/${store.state.user.active_user.ref}/data`,
                     {tenant_data: tenant_data})
  },

  loadDataFromS3(level) {
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/data/s3_load/${level}`)
  },

  fetchDefaultUserSettings() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/users/default`)
  },
};