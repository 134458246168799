import Axios from "axios";
import {store} from '../../store/';

export const HospitalSetting = {
  fetchHospitalSettings() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/hospital_setting`)
  },

  addAdminBurdenWeighting(data) {
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/admin_burden_weightings`, {admin_burden_weighting: data})
  },

  updateAdminBurdenWeighting(data) {
    return Axios.patch(`/hospitals/${store.state.user.active_user.ref}/admin_burden_weightings/${data.id}`, {admin_burden_weighting: data})
  },

  deleteAdminBurdenWeighting(data) {
    return Axios.delete(`/hospitals/${store.state.user.active_user.ref}/admin_burden_weightings/${data.id}`)
  },

  wardIds() {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/wards/ids`)
  },

  saveWardCheckConfig(data) {
    try {
      return Axios.patch(`/hospitals/${store.state.user.active_user.ref}/hospital_setting`, data)
    } catch (e) {
      console.error("ERROR: ", e)
    }
  }
};