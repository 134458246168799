import Vue from 'vue';
import {Group} from "../../api";
import {SET_USER_DRUG_GROUPS, DELETE_DRUG_GROUP, SET_WARD_GROUPS} from "./types/group_types";
import {SET_DRUG_GROUP_DATA} from "./types/dashboard_types";
import {group} from './state/group';

const state = group;

const actions = {
  async createGroup({commit}, {data}) {
    const response = await Group.create_group(data);
    // Doesn't update the drop down list dynamically in a dashboard (yet)
    //
    commit(SET_USER_DRUG_GROUPS , {data: response.data});
    return response;
  },

  async fetchUserDrugGroups({commit}) {
    const response = await Group.fetch_user_drugs_groups();
    commit(SET_USER_DRUG_GROUPS, {data: response.data});
    return response;
  },

  async deleteGroup({commit}, {group}) {
    const response = await Group.delete_group(group);
    commit(DELETE_DRUG_GROUP, {data: group});
    return response;
  },

  async fetchWardGroups({commit, state}) {
    if(!_.isEmpty(state.wardGroups.hierarchy)) {
      return;
    }
    commit(SET_WARD_GROUPS, {data: {hierarchy: [['Loading', null]]}})
    const response = await Group.fetch_ward_groups();
    commit(SET_WARD_GROUPS, {data: response.data})
    return response;
  }
};

const mutations = {
  [SET_USER_DRUG_GROUPS](state, {data}) {
    Vue.set(state, 'userDrugGroups', data)
  },
  [SET_WARD_GROUPS](state, {data}) {
    state.wardGroups = data
  },
  [DELETE_DRUG_GROUP](state, {data}) {
    const idx = _.findIndex(state.userDrugGroups, dl => dl.id === data.id);
    Vue.delete(state.userDrugGroups, idx);
  }
};

export default {
  namespace: true,
  state,
  actions,
  mutations
}