<template>
  <div>
    <b-nav-item-dropdown class="settings-dropdown" no-caret right>
      <template slot="button-content">
        <i class="las la-cog px-2 la-2x"/>
      </template>
      <b-dropdown-item>
        <router-link :to="$linkgen('/groups/drugs')">
          <i class="las la-capsules pl-0 pr-2 la-lg"/>{{$t('manage_drug_groups')}}
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item @click="logout()"><i class="las la-sign-out-alt pl-0 pr-2 la-lg"/>{{$t('log_out')}}</b-dropdown-item>
    </b-nav-item-dropdown>

<!--    <b-nav-item-dropdown class="settings-dropdown d-sm-none" no-caret right>-->
<!--      <template slot="button-content">-->
<!--        <span class="square square-lg bg-gray text-white"><i class="las la-cog la-2x"></i></span>-->
<!--      </template>-->
<!--      <b-dropdown-item>-->
<!--        <router-link :to="$linkgen('/groups/drugs')">-->
<!--          <i class="las la-capsules pl-0 pr-2 la-lg"/>{{$t('manage_drug_groups')}}-->
<!--        </router-link>-->
<!--      </b-dropdown-item>-->
<!--      <b-dropdown-item @click="logout()"><i class="las la-sign-out-alt pl-0 pr-2 la-2x la-lg"/>{{$t('log_out')}}</b-dropdown-item>-->
<!--    </b-nav-item-dropdown>-->
  </div>
</template>

<script>
  export default {
    methods: {
      async logout() {
        await this.$store.dispatch('logout');
        this.$router.push({path: '/login'})
      }
    }
  }
</script>

<style>
  .settings-dropdown a {
    color: black;
  }

  .settings-dropdown a:hover {
    text-decoration: none;
  }

  .settings-dropdown .dropdown-item {
    padding: 0.25rem 1rem;
  }
</style>