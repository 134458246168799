const methods = {
  hasRole(role) {
    return this.current_user && this.current_user.username && _.includes(this.current_user.roles, role);
  },

  userModel(obj = null) {
    let out = obj
    if (!obj) {
      out = {}
    }

    out.ref = null;
    out.name = null;
    out.email = null;
    out.username = null;
    out.password = null;
    out.force_password_change = false;
    out.filter_setting = {};
    out.display_setting = {};
    out.role_ids = [];
    out.roles_available = [];
    out.errors = null;

    return out;
  },
  setUserModel(model1, model2) {
    model1.ref = model2.ref;
    model1.name = model2.name;
    model1.email = model2.email;
    model1.username = model2.username;
    model1.force_password_change = model2.force_password_change;
    model1.filter_setting = model2.filter_setting;
    model1.display_setting = model2.display_setting;
    model1.roles = model2.roles;
    model1.roles_available = model2.roles_available;
  }
}

export default {
  data() {
    return {
      active_user: this.$store.state.user.active_user,
      current_user: this.$store.state.user.current_user,
    }
  },
  methods
}