export const SET_ERRORS = 'SET_ERRORS';
export const SET_ERROR_INFO = 'SET_ERROR_INFO';
export const DELETE_ERROR = 'DELETE_ERROR';
export const DELETE_ERROR_LIKE_THIS = 'DELETE_ERROR_LIKE_THIS';

export const SET_USER= 'SET_USER';

export const SET_HOSPITALS = 'SET_HOSPITALS';
export const SET_USER_IN_LIST = 'SET_USER_IN_LIST';

export const ADD_HOSPITAL = 'ADD_HOSPITAL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_HOSPITAL = 'DELETE_HOSPITAL';

export const SET_DATA_INFO = 'SET_DATA_INFO';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_DATA_STATUS = 'SET_DATA_STATUS';
export const SET_USER_PASSWORD_CHANGE_URL = 'SET_USER_PASSWORD_CHANGE_URL';

export const SET_EXTRACTORS = 'SET_EXTRACTORS';
export const SET_DATA_OPERATIONS = 'SET_DATA_OPERATIONS';
export const SET_DATA_OPERATION = 'SET_DATA_OPERATION';
export const SET_DATA_LOGS = 'SET_DATA_LOGS';

export const DELETE_DATA_LOG = 'DELETE_DATA_LOG';
export const SET_EXTRACTOR = 'SET_EXTRACTOR';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
