export default {
  patientUsageStatsFilter(data, args) {
    if (_.isEmpty(data)) return {data: []};
    if (_.isEmpty(data.meta)) return data;
    const comp = args.__context.$parent;
    const options = {
      wardIdCache: comp.$store.state.dashboard.ward_id_cache,
      drugIdCache: comp.$store.state.dashboard.drug_id_cache,
      useTopLevelDrugGroup: true,
      drugAppendContext: true
    };

    data.data['percentage_treated'] = ((data.data['median_patient_per_day'] / data.data['median_bed_days']) * 100).toFixed(1);
    data.data['percentage_administered_patients_over_60'] = ((data.data['administered_patients_over_60'] / data.data['patient_count']) * 100).toFixed(1);
    data.data['percentage_administered_patients_over_90'] = ((data.data['administered_patients_over_90'] / data.data['patient_count']) * 100).toFixed(1);
    data.data['percentage_administered_doses_over_60'] = ((data.data['administered_doses_over_60'] / data.data['total_doses']) * 100).toFixed(1);
    data.data['percentage_administered_doses_over_90'] = ((data.data['administered_doses_over_90'] / data.data['total_doses']) * 100).toFixed(1);

    const contentRowTemplate = '1fr 1fr';
    const contentColTemplate = '1fr';
    let outNodes = [];
    let values = [{
      text: `<b class="size-up">${data.data['patient_count']}</b> patients`,
      type: 'circle',
      grid: {row: 1, col: 1}
    },
      {
        text: `On average <b class="size-up">${data.data['median_patient_per_day']}</b> patient's per day`,
        type: 'circle',
        grid: {row: 1, col: 2}
      }]

    let subs = ['drugs', 'dateRangeInterval']
    let header = comp.titlerFromKey('patient_usage_last_days_header', comp.currentViewState, subs, options, data, data.data);
    outNodes.push({
      content: {
        header: {text: header, position: {row: 1, col: 1}},
        highlights: {values: values, position: {row: 2, col: 1}, grid: {rowTemplate: '1fr', colTemplate: '1fr 1fr'}},
        grid: {rowTemplate: contentRowTemplate, colTemplate: contentColTemplate, gap: '10px'}
      },
      position: {row: 1, col: 1},
      arrows: [{direction: 'right-arrow'}]
    });

    subs = ['dateRangeInterval']
    header = comp.titlerFromKey('patient_usage_bed_days_header', comp.currentViewState, subs, options, data, data.data);
    values = [{text: `<b class="size-up">${data.data['median_bed_days']}</b>`, type: 'circle', grid: {row: 1, col: 1}}]

    outNodes.push({
      content: {
        header: {text: header, position: {row: 1, col: 1}},
        highlights: {values: values, position: {row: 2, col: 1}, grid: {rowTemplate: '1fr', colTemplate: '1fr'}},
        grid: {rowTemplate: contentRowTemplate, colTemplate: contentColTemplate, gap: '10px'}
      },
      position: {row: 1, col: 2},
      arrows: [{direction: 'bottom-arrow'}]
    });

    subs = ['dateRangeInterval', 'drugs']
    header = comp.titlerFromKey('patient_usage_percentage_treated_header', comp.currentViewState, subs, options, data, data.data);
    values = [{
      text: `<b class="size-up">${data.data['percentage_treated']}%</b>`,
      type: 'circle',
      grid: {row: 1, col: 1}
    }]

    outNodes.push({
      content: {
        header: {text: header, position: {row: 1, col: 1}},
        highlights: {values: values, position: {row: 2, col: 1}, grid: {rowTemplate: '1fr', colTemplate: '1fr'}},
        grid: {rowTemplate: contentRowTemplate, colTemplate: contentColTemplate, gap: '10px'}
      },
      position: {row: 2, col: 2},
      arrows: [{direction: 'left-arrow'}, {direction: 'right-arrow'}]
    });

    data.data['delay'] = 60;
    subs = ['dateRangeInterval', 'drugs', 'delay']
    header = comp.titlerFromKey('patient_usage_patients_late_header', comp.currentViewState, subs, options, data, data.data);
    values = [{
      text: `<b class="size-up">${data.data['administered_patients_over_60']}</b><br> (${data.data['percentage_administered_patients_over_60']}%)`,
      type: 'circle',
      grid: {row: 1, col: 1}
    }]
    outNodes.push({
      content: {
        header: {text: header, position: {row: 1, col: 1}},
        highlights: {values: values, position: {row: 2, col: 1}, grid: {rowTemplate: '1fr', colTemplate: '1fr'}},
        grid: {rowTemplate: contentRowTemplate, colTemplate: contentColTemplate, gap: '10px'}
      },
      position: {row: 2, col: 1},
      arrows: [{direction: 'bottom-arrow'}]
    });

    data.data['delay'] = 90;
    subs = ['dateRangeInterval', 'drugs', 'delay']
    header = comp.titlerFromKey('patient_usage_patients_late_header', comp.currentViewState, subs, options, data, data.data);
    values = [{
      text: `<b class="size-up">${data.data['administered_patients_over_90']}</b><br> (${data.data['percentage_administered_patients_over_90']}%)`,
      type: 'circle',
      grid: {row: 1, col: 1}
    }]
    outNodes.push({
      content: {
        header: {text: header, position: {row: 1, col: 1}},
        highlights: {values: values, position: {row: 2, col: 1}, grid: {rowTemplate: '1fr', colTemplate: '1fr'}},
        grid: {rowTemplate: '1fr 1fr', colTemplate: '1fr', gap: '10px'}
      },
      position: {row: 2, col: 3},
      arrows: [{direction: 'bottom-arrow'}]
    });

    data.data['delay'] = 60;
    subs = ['dateRangeInterval', 'drugs', 'delay']
    header = comp.titlerFromKey('patient_usage_doses_late_header', comp.currentViewState, subs, options, data, data.data);
    values = [{
      text: `<b class="size-up">${data.data['administered_doses_over_60']}</b><br> (${data.data['percentage_administered_doses_over_60']}%)`,
      type: 'circle',
      grid: {row: 1, col: 1}
    }]
    outNodes.push({
      content: {
        header: {text: header, position: {row: 1, col: 1}},
        highlights: {values: values, position: {row: 2, col: 1}, grid: {rowTemplate: '1fr', colTemplate: '1fr'}},
        grid: {rowTemplate: contentRowTemplate, colTemplate: contentColTemplate, gap: '10px'}
      },
      position: {row: 3, col: 1},
      arrows: [{direction: 'bottom-arrow'}]
    });

    subs = ['drugs', 'delay']
    header = comp.titlerFromKey('patient_usage_doses_late_rank_header', comp.currentViewState, subs, options, data, data.data);

    subs = ['drugs']
    let likelyhoodDrugHeader = comp.titlerFromKey('patient_usage_likelihood_drug', comp.currentViewState, subs, options, data, data.data);
    outNodes.push({
      content: {
        header: {text: header, position: {row: 1, col: 1}},
        table: {
          position: {row: 1, col: 1},
          headers: [comp.$t('ward'), comp.$t('patient_usage_likelihood_general'), likelyhoodDrugHeader],
          cols: [{field: 'location_label'},
            {field: 'bayes_overall_avg_60', filter: 'percentage'},
            {field: 'bayes_drug_avg_60', filter: 'percentage'}
          ],
          rows: _.sortBy(data.data['late_ranking'],[(d)=> -d.bayes_drug_avg_60]).slice(0,5)
        },
        grid: {rowTemplate: '1fr', colTemplate: '1fr'}
      },
      position: {row: 4, col: 1},
      arrows: [{direction: 'right-arrow'}]
    });

    data.data['delay'] = 90;
    subs = ['dateRangeInterval', 'drugs', 'delay']
    header = comp.titlerFromKey('patient_usage_doses_late_header', comp.currentViewState, subs, options, data, data.data);
    values = [{
      text: `<b class="size-up">${data.data['administered_doses_over_90']}</b><br> (${data.data['percentage_administered_doses_over_90']}%)`,
      type: 'circle',
      grid: {row: 1, col: 1}
    }]
    outNodes.push({
      content: {
        header: {text: header, position: {row: 1, col: 1}},
        highlights: {values: values, position: {row: 2, col: 1}, grid: {rowTemplate: '1fr', colTemplate: '1fr'}},
        grid: {rowTemplate: contentRowTemplate, colTemplate: contentColTemplate, gap: '10px'}
      },
      position: {row: 3, col: 3},
      arrows: [{direction: 'bottom-arrow'}]
    });

    subs = ['drugs', 'delay']
    header = comp.titlerFromKey('patient_usage_doses_late_rank_header', comp.currentViewState, subs, options, data, data.data);

    subs = ['drugs']
    likelyhoodDrugHeader = comp.titlerFromKey('patient_usage_likelihood_drug', comp.currentViewState, subs, options, data, data.data);
    outNodes.push({
      content: {
        header: {text: header, position: {row: 1, col: 1}},
        table: {
          position: {row: 1, col: 1},
          headers: [comp.$t('ward'), comp.$t('patient_usage_likelihood_general'), likelyhoodDrugHeader],
          cols: [{field: 'location_label'},
            {field: 'bayes_overall_avg_90', filter: 'percentage'},
            {field: 'bayes_drug_avg_90', filter: 'percentage'}
          ],
          rows: _.sortBy(data.data['late_ranking'],[(d)=> -d.bayes_drug_avg_90]).slice(0, 5)
        },
        grid: {rowTemplate: '1fr', colTemplate: '1fr'}
      },
      position: {row: 4, col: 3},
      arrows: [{direction: 'left-arrow'}]
    });
    data.data['percentile_percent'] = 10;
    subs = ['percentile_percent']
    header = comp.titlerFromKey('patient_usage_percentile_header', comp.currentViewState, subs, options, data, data.data);
    values = [{
      text: `<b class="size-up">${data.data['percentile_90']}</b> minutes`,
      type: 'circle',
      grid: {row: 1, col: 1}
    }]
    outNodes.push({
      content: {
        header: {text: header, position: {row: 1, col: 1}},
        highlights: {values: values, position: {row: 2, col: 1}, grid: {rowTemplate: '1fr', colTemplate: '1fr'}},
        grid: {rowTemplate: contentRowTemplate, colTemplate: contentColTemplate, gap: '10px'}
      },
      position: {row: 4, col: 2},
    });

    // subs = ['median_bed_days', 'dateRangeInterval', 'percentage_treated', 'drugs']
    // text = comp.titlerFromKey('patient_usage_percentage_treated', comp.currentViewState, subs, options, data, data.data);
    // outNodes.push({header: header, text: text, items: values, grid: {row: 1, col: '3 / 5'}, arrows: [{direction: 'bottom-arrow'}]});
    // outNodes.push({header: comp.$t('patient_usage_percentage_treated_header'), text: text});
    //
    // outNodes.push({name: args.__context.$t('patient_usage_late_v1', data)});
    // outNodes.push({name: args.__context.$t('patient_usage_late_v2', data)});
    // outNodes.push({name: args.__context.$t('patient_usage_late_v1_breakdown', data)});
    // outNodes.push({name: args.__context.$t('patient_usage_late_v2_breakdown', data)});

    const out = {config: {rowTemplate: '1fr 1fr', colTemplate: '1fr 1fr 1fr'}, data: outNodes}
    return {data: out, filters: data.filters, meta: data.meta};
  }
}