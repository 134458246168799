import Axios from 'axios';
import {store} from '../../store/';
import qs from "qs";

export const DrugInfo = {
  drugInfo() {
    return Axios.get(`/drug_info`)
  },

  resetDmd() {
    return Axios.post(`/drug_info/reset_dmd`)
  },

  updateGroups() {
    return Axios.post(`/drug_info/update_groups`)
  },

  regenerateDmd() {
    return Axios.post(`/drug_info/regenerate_dmd`)
  },

  resetDmdSup() {
    return Axios.post(`/drug_info/reset_dmd`)
  },

  regenerateDmdSup() {
    return Axios.post(`/drug_info/regenerate_dmd`)
  },

  resetSnomed() {
    return Axios.post(`/drug_info/reset_snomed`)
  },

  regenerateSnomed() {
    return Axios.post(`/drug_info/regenerate_snomed`)
  },

  regenerateDict() {
    return Axios.post(`/drug_info/regenerate_dictionary`)
  },

  async vtmToName(ids) {
    const params = qs.stringify({q: ids}, {arrayFormat: 'brackets', skipNulls: true, encode: false});
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/drugs/code_search?${params}`);
  },

  async drugProductSearch(term) {
    const params = qs.stringify({q: term}, {arrayFormat: 'brackets', skipNulls: true, encode: false});
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/drugs/product_search?${params}`);
  },

  async drugProductFromIds(ids) {
    const params = qs.stringify({q: ids}, {arrayFormat: 'brackets', skipNulls: true, encode: false});
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/drugs/product_id_search?${params}`);
  }
};