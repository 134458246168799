<template>
  <b-navbar toggleable="sm" class="header d-print-none">
    <b-navbar-brand href="/" class="text-green-900"><img style="height: 23px; width:180px;"
                                                         src="../../../images/logo.svg"></b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav v-if="current_user.username">
      <b-navbar-nav class="flex-fill justify-content-end" >
        <b-nav-item class="ml-sm-4" right :to="`/hospitals/${active_user.username}/${active_user.ref}`" v-if="hasRole('admin')">
          <div v-html="$t('hospital_dashboards', {hospital: active_user.username})"></div>
        </b-nav-item>
        <b-nav-item class="ml-sm-4" right to="/" v-else>{{ $t('dashboards') }}</b-nav-item>

        <b-nav-item class="ml-sm-4" right :to="`/hospitals/${active_user.username}/${active_user.ref}/advanced_settings`"
                    v-if="hasRole('admin')">
          <div v-html="$t('hospital_settings', {hospital: active_user.username})"></div>
        </b-nav-item>

        <b-nav-item class="ml-sm-4" right to="/settings/hospital" v-if="hasRole('super_user')">
          <div v-html="$t('hospital_settings', {hospital: hospitalName})"></div>
        </b-nav-item>

        <b-nav-item  class="ml-sm-4" right to="/settings/user" v-if="!hasRole('admin')">{{ hasRole('super_user') ? $t('my_settings') : $t('settings')}}</b-nav-item>
        <b-nav-item  class="ml-sm-4" right @click="logout()">{{ $t('log_out') }}</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import SettingsMenuView from './SettingsMenuView.vue'
import UserHelpers from '../../mixins/user_helpers'

export default {
  components: {SettingsMenuView},
  mixins: [UserHelpers],
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
      await this.$store.dispatch('resetDashboardInfo');
      await this.$store.dispatch('resetQueryData');
      this.$router.push({path: '/login'})
    }
  },
  computed: {
    hospitalName() {
      return this.$store.state.user.current_user.hospital_name;
    }
  }
}
</script>

<style>
.settingsDropdown .dropdown-item:focus {
  outline: none;
}

#nav-collapse > ul > li > a.nav-link {
  color: #174c20;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .2em;
  background-color: #FFFFFF;
  padding: 0.5rem 1rem;
}

#nav-collapse > ul > li > a.nav-link:hover {
  color: #1f742e;
}

/*  background-color: #3abf94;*/
/*  border: 1px solid #3abf94;*/
/*  color: #FFFFFF;*/
/*}*/

/*.navbar .nav .nav-item .nav-link:hover {*/
/*  color: #3c484f;*/
/*  background: transparent;*/
/*  text-decoration: none;*/
/*}*/

/*.navbar .nav .nav-item .nav-link .las {*/
/*  font-size: 20px;*/
/*}*/

.header {
  flex: 0 1 auto;
  max-height: 30px;
  z-index: 100;
  padding: 7px 1.85rem !important;
  background: #ffffff;
}

@media (max-width: 991.98px) {
  .header {
    padding: 7px 10px;
  }
}

.headerLink {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 992px) and (min-height: 670px), (max-width: 767px) {
  .headerLink {
    font-size: 1rem;
  }
}

.headerLink a {
  display: block;
  color: #6c757d;
  text-decoration: none;
  cursor: pointer;
}

.headerLink:last-child > a {
  border-bottom: 1px solid transparent;
}

.headerLink > a,
.headerLink > div a {
  position: relative;
  padding-left: 50px;
  line-height: 35px;
  border-top: 1px solid transparent;
}

.headerLink > a:hover,
.headerLink > div a:hover {
  background-color: #f9fafe;
  text-decoration: none;
  color: #6c757d;
}

.headerLink > a > i,
.headerLink > div a > i {
  margin-right: 7px;
}

@media (min-width: 992px) and (min-height: 670px), (max-width: 767px) {
  .headerLink > a,
  .headerLink > div a {
    line-height: 55px;
  }
}

.headerLink .icon {
  font-size: 1.1rem;
  display: block;
  position: absolute;
  top: 3px;
  left: 11px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
}

@media (min-width: 992px) and (min-height: 670px), (max-width: 767px) {
  .headerLink .icon {
    top: 12px;
  }
}

.headerLink .badge {
  float: right;
  line-height: 8px;
  margin-top: 16px;
  margin-right: 15px;
  padding: 7px;
}
</style>