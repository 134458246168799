import Vue from 'vue'
import App from './views/App.vue'
import {createRouter} from './router'
import {createLocale} from './locale'
import {store} from './store'
import AxiosDefaults from 'axios/lib/defaults'
import FlashPlugin from './plugins/flash_plugin'
import LinkGenPlugin from './plugins/link_gen_plugin'

import VueSelect from 'vue-select'
import BootstrapVue from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import ToggleButton from 'vue-js-toggle-button'
import JsonTreeView from 'vue-json-tree-view'
import Multiselect from 'vue-multiselect'

import * as Sentry from '@sentry/browser'

import * as echarts from 'echarts'

import echartsTheme from './component/echarts/config/westeros.json'

import VueObserveVisibility from 'vue-observe-visibility'
import VueResize from 'vue-resize'

import './filters/filters'

async function createApp() {
  Vue.config.errorHandler = function (error, vm, info) {
    console.log(error, vm, info);
  };

  Sentry.init({
    dsn: 'https://406d01bda7654f2aa9866f428f6354c0@sentry.triscribe.net/3',
    integrations: [new Sentry.Integrations.Vue({ Vue })]
  });


  echarts.registerTheme('westeros', echartsTheme);

  const el = $('#app-container');

  const rolesStr = el.attr('roles');
  const roles = rolesStr ? JSON.parse(rolesStr) : {};

  await store.dispatch('setCurrentUser', {
    ref: el.attr('ref'),
    username: el.attr('username'),
    roles: roles,
    hospital_name: el.attr('hospital_name'),
    hospital_ref: el.attr('hospital_ref'),
    api_token: el.attr('api_token')
  });

  Vue.component('v-select', VueSelect);
  Vue.component('multiselect', Multiselect)
  Vue.use(require('vue-moment'));

  Vue.use(BootstrapVue);
  Vue.use(VueClipboard);
  Vue.use(ToggleButton);
  Vue.use(VueObserveVisibility);
  Vue.use(VueResize);
  Vue.use(LinkGenPlugin, {store: store});
  Vue.use(FlashPlugin);
  Vue.use(JsonTreeView);

  const i18n = createLocale();
  const router = createRouter(i18n);

  const app = new Vue({
    router,
    i18n,
    store,
    el: '#app-container',
    render: h => h(App)
  });

  return {app, router};
}

document.addEventListener('DOMContentLoaded', function () {
  AxiosDefaults.baseURL = '/api/';
  AxiosDefaults.headers.common['X-CSRF-Token'] = $('meta[name="csrf-token"]').attr('content')

  createApp();
});