<template>
  <nav id="sidebar" :class="[{'static-sidebar': sidebar.static}, 'admin-sidebar']" role="navigation">
    <div class="sidebar-status d-md-none">
      <b-nav class="float-right">
        <settings-menu-view></settings-menu-view>
      </b-nav>
    </div>
    <client-sidebar-view v-if="current_user.username != null && !hasRole('admin')"></client-sidebar-view>
    <admin-sidebar-view v-if="current_user.username != null && hasRole('admin')"></admin-sidebar-view>
  </nav>
</template>

<script>
  import ClientSidebarView from '../user/layout/ClientSidebarView.vue';
  import AdminSidebarView from '../admin/layout/AdminSidebarView.vue';
  import SettingsMenuView from './SettingsMenuView.vue';
  import UserHelpers from '../../mixins/user_helpers';

  export default {
    data() {
      return {
        current_user: this.$store.state.user.current_user,
        sidebar: this.$store.state.view_model.sidebar
      }
    },
    mixins: [UserHelpers],
    components: {ClientSidebarView, AdminSidebarView, SettingsMenuView},
  }
</script>

<style>
  .admin-sidebar {
    width: 210px;
    padding-top: 50px;
  }

  .static-sidebar .logo {
    width: 50px;
  }

  .close-sidebar .navTitle {
    opacity: 0;
  }
  .close-sidebar .labelName[data-v-f46d2704] {
    opacity: 0;
  }

  .static-sidebar .logo {
    width: 100%;
    -webkit-transition: none;
    transition: none;
  }

  .static-sidebar .labelName {
    -webkit-transition: none;
    transition: none;
    opacity: 1;
  }
  .static-sidebar .navTitle {
    opacity: 1;
    -webkit-transition: none;
    transition: none;
  }

  .sidebarClose .sidebarLabels > li > a > i[data-v-f46d2704] {
    margin-left: 8px;
    -webkit-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
  }
  .sidebarStatic .sidebarLabels > li > a > i[data-v-f46d2704] {
    -webkit-transition: none;
    transition: none;
    margin-left: 0;
  }
</style>