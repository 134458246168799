import Axios from 'axios';
import {store} from '../../store/';

export const DrugMapping = {
  remapHospitalData(hospitalRef, name, mapping_type) {
    if(!hospitalRef) {
      hospitalRef = store.state.user.active_user.ref
    }
    return Axios.post(`/hospitals/${hospitalRef}/mappings/map/${mapping_type}/${name}`)
  },

  getDrugMappings(page, perPage, status) {
    return Axios.get(`/hospitals/${store.state.user.active_user.ref}/drug_mappings`, {params: {page: page, per_page: perPage, status: status}})
  },

  updateDrugMapping(drug) {
    return Axios.put(`/hospitals/${store.state.user.active_user.ref}/drug_mappings/${drug.id}`,
      {drug: {type: drug.correction_type, id: drug.correction_id}})
  },

  verifyDrugMapping(drug) {
    return Axios.post(`/hospitals/${store.state.user.active_user.ref}/drug_mappings/${drug.id}/verify`)
  },
};