<template>
  <div id="app" :class="{'with-sidebar': current_user.username != null && hasRole('admin')}">
    <header-view></header-view>

    <div class="main-content" :class="[{'admin-layout': current_user.username != null && hasRole('admin')}, 'main-content']">
      <side-bar-view v-if="current_user.username != null && hasRole('admin')"></side-bar-view>

      <div class="content-wrapper">
        <flash></flash>
        <main class="content" role="main">
          <router-view></router-view>
        </main>
      </div>
    </div>
    <footer class="footer">
      <div class="copyright">
        {{ (new Date()).getFullYear() }} © {{ $t('brand') }}
      </div>
    </footer>
  </div>
</template>

<script>
import SideBarView from '../views/layout/SidebarView.vue';
import HeaderView from '../views/layout/HeaderView.vue';
import Flash from '../component/Flash.vue';
import UserHelpers from '../mixins/user_helpers';

export default {
  data() {
    return {
      current_user: this.$store.state.user.current_user,
      sidebar: this.$store.state.view_model.sidebar
    }
  },
  props: ['username', 'roles'],
  mixins: [UserHelpers],
  components: {SideBarView, HeaderView, Flash}
}
</script>

<style>
#app{
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-flow: column;
}

.main-content {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

.content {
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 0;
  max-height: 100%;
}

.content-wrapper {
  flex-basis: 0;
  flex-grow: 999;
  max-height: 100%;
}

.footer {
  padding: 10px;
  background: #FFFFFF;
  flex-direction: row;
  width: 100%
}

.copyright {
  display: flex;
  justify-content: center;
}

.flasher ._vue-flash-msg-body {
  z-index: 100000;
}

.admin-layout {
  display: flex;
  flex-direction: row;
}
</style>