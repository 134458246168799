export function handleDrugInfo(response, commit)  {
  const UNKNOWN = "Unknown version";
  const NEVER_RETRIEVED = "Never retrieved/Reset";

  const data = response.data;

  if(data.dmd_release_date) {
    data.dmd_release_date = moment(data.dmd_release_date).format('DD/MM/YYYY')
  } else {
    data.dmd_release_date = NEVER_RETRIEVED
  }

  if(data.dmd_sup_release_date) {
    data.dmd_sup_release_date = moment(data.dmd_sup_release_date).format('DD/MM/YYYY');
  } else {
    data.dmd_sup_release_date = NEVER_RETRIEVED
  }

  if(data.snomed_release_date) {
    data.snomed_release_date = moment(data.snomed_release_date).format('DD/MM/YYYY')
  } else {
    data.snomed_release_date = NEVER_RETRIEVED
  }

  if(!data.dmd_release_version) {
    data.dmd_release_version = NEVER_RETRIEVED
  } else if(data.dmd_release_version == '100000.0.0') {
    data.dmd_release_version = UNKNOWN
  }

  if(!data.dmd_sup_release_version) {
    data.dmd_sup_release_version = NEVER_RETRIEVED
  } else if(data.dmd_sup_release_version == '100000.0.0') {
    data.dmd_sup_release_version = UNKNOWN
  }

  if(!data.snomed_release_version) {
    data.snomed_release_version = NEVER_RETRIEVED
  } else if(data.snomed_release_version == '100000.0.0') {
    data.snomed_release_version = UNKNOWN
  }

  if(data.specialty_codes_date) {
    data.specialty_codes_date = moment(data.specialty_codes_date).format('DD/MM/YYYY')
  }
  else {
    data.specialty_cods_date = NEVER_RETRIEVED
  }

  commit('SET_DRUG_INFO', response.data);
  return response.data;
}

export default {handleDrugInfo}