export default {
  drugDelayCountFilter(data, _args) {
    let out = [];
    _.forEach(data.data, (item) => {
      // No point trying to divide by something that is zero or if there have
      // been no delayed administrations smaller than the first grouping don't show it either
      //
      if(item[data.meta.denominator] === 0 || item[data.meta.groupings[0]] === 0) return;

      _.forEach(data.meta.groupings, (group) => {
        item[`${group}_percent`] = ((item[group] / item[data.meta.denominator])*100).toFixed(1)
      })

      out.push(item)
    })

    return {data: (data.data ? out : null), filters: data.filters, meta: data.meta};
  },

  drugDelayTimelineFilter(data, _args) {
    let out = [];
    _.forEach(data.data, (item) => {
      _.forEach(data.meta.groupings, (group) => {
        if(item[data.meta.denominator] === 0) {
          item[`${group}_percent`] = 0
        } else {
          item[`${group}_percent`] = ((item[group] / item[data.meta.denominator])*100).toFixed(1)
        }
      })

      out.push(item)
    })

    return {data: (data.data ? out : null), filters: data.filters, meta: data.meta};
  },

  parkinsonsEarlyLateTrendsDataFilter(data, _args) {
    if (_.isEmpty(data)) return {data: []};
    if (_.isEmpty(data.meta)) return data;

    let out = [];
    _.forEach(data.data, (item) => {
      Object.keys(item).forEach((key) => {
        if (key === 'date' || key === 'total') return;

        item[`${key}_percent`] = item.total && item.total !== 0 ? ((item[key] / item.total) * 100).toFixed(2) : 0;
        data.meta.labels[`${key}_percent`] = data.meta.labels[key]
      });
      out.push(item)
    });

    return {data: out, filters: data.filters, meta: data.meta};
  },
}