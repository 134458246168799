export default {
  performanceWardDataFilter(data, args) {
    if (_.isEmpty(data) || _.isEmpty(data.data)) return {data: []};
    if (_.isEmpty(data.meta)) return data;

    let out = [{count: data.data.early_count, count_percent: data.data.early_percent, name: data.meta.labels.early.short_label, id: 'early'},
      {count: data.data.late_count, count_percent: data.data.late_percent, name: data.meta.labels.late.short_label, id: 'late'},
      {count: data.data.on_time_count, count_percent: data.data.on_time_percent, name: data.meta.labels.on_time.short_label, id: 'on_time'},
      {count: data.data.omitted_count, count_percent: data.data.omitted_percent, name: data.meta.labels.omitted.short_label, id: 'omitted'},
    ];
    return {data: out, filters: data.filters, meta: data.meta};
  }
}