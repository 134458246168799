import Axios from 'axios';
import {store} from '../../store/';
import qs from "qs";
import Vue from 'vue'


export const Cache = {
  // async populateDrugIdCache(params, existing) {
  // },

  async populateWardIdCache(params, existing) {
    let required = params.ward_ids;
    if(params.ward_id) {
      required = required || [];
      required.push(params.ward_id);
    }

    return this.populateIdCache(`/hospitals/${store.state.user.active_user.ref}/wards/id_search`,
                                required, existing, 'mapped_location_id', 'name');
  },

  async populateDrugInfoCache(params, existing) {
    let required = params.substance_ids
    return this.populateIdCache(`/hospitals/${store.state.user.active_user.ref}/drugs/substance_info_search`,
      required, existing, params.meta_substance_id, null);
  },
  async populateDrugIdCache(params, existing) {
    const required = params.drug_ids;
    if (_.isEmpty(required)) {
      return existing;
    }

    const url = `/hospitals/${store.state.user.active_user.ref}/drugs/code_search`
    let req = required;
    if(typeof(req[0]) === 'object') {
      req = _.map(req, (v) => JSON.stringify(v))
    }

    let cacheMiss = [];
    _.forEach(req, (v) => {
      if (!(v in existing)) {
        cacheMiss.push(JSON.parse(v))
      }
    });

    if (_.isEmpty(cacheMiss)) {
      return existing;
    }

    const strParams = qs.stringify({q: cacheMiss}, {arrayFormat: 'brackets', skipNulls: true, encode: false});

    _.forEach(required, (v) => {
      if(!existing[v]){
        existing[v] = null
      }
    });

    const response = await Axios.get(`${url}?${strParams}`);

    let data = {}
    response.data.forEach((v) => {
      const attr = _.find(Object.keys(v), (i) => i !== 'name');
      let key = {};
      key[attr] = v[attr];
      data[JSON.stringify(key)] = v.name
    });
    return data;
  },

  async populateIdCache(url, required, existing, attr, label) {
    if (_.isEmpty(required)) {
      return existing;
    }

    let cacheMiss = [];
    _.forEach(required, (v) => {
      if (!(v in existing)) {
        cacheMiss.push(v)
      }
    });

    if (_.isEmpty(cacheMiss)) {
      return existing;
    }

    const params = qs.stringify({q: cacheMiss}, {arrayFormat: 'brackets', skipNulls: true, encode: false});

    _.forEach(required, (v) => {
      if(!existing[v]){
        existing[v] = null
      }
    });

    const response = await Axios.get(`${url}?${params}`);

    let data = {}
    if(_.isArray(response.data)) {
      response.data.forEach((v) => data[v[attr]] = v[label]);
      return data;
    }

    Object.values(response.data).forEach((v) => {data[v[attr]] = v});
    return data;
  }


};