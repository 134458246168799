export const hospital_setting = {
  admin_burden_levels: {
    min: 1,
    max: 5,
    step: 0.01
  },
  admin_burden_weightings: [],
  vm: {
    groups: []
  },
  ward_check_config: []
};