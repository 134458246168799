export default {
  routeDataFilter(data, _args) {
    if (_.isEmpty(data)) return {data: []};
    if (_.isEmpty(data.meta)) return data;

    let out = [];
    _.forEach(data.data, (item) => {

      let total = 0;
      Object.keys(item).forEach((key) => {
        if (key === 'date') return;
        total += item[key];
      });

      Object.keys(item).forEach((key) => {
        if (key === 'date') return;

        item[`${key}_percent`] = (total !== 0 ? ((item[key] / total) * 100).toFixed(2) : 0);
        data.meta.labels[`${key}_percent`] = data.meta.labels[key]
      });
      out.push(item)
    });

    return {data: out, filters: data.filters, meta: data.meta};
  },

  orderLengthDataFilter(data, args) {
    if (_.isEmpty(data)) return {data: []};
    if (_.isEmpty(data.meta)) return data;

    const maxOrderDays = data.meta.max_order_days;
    const unknownOrderDays = data.meta.unknown_order_days;

    let count = 0;
    let ordering = [];
    data.meta.order_days_config.forEach(function (range) {
      if (range[0] === (unknownOrderDays)) return;
      const end = (range[1] === maxOrderDays ? range[0] : range[1]);
      for (let i = range[0]; i <= end; i++) {
        ordering[i] = count;
      }
      count++;
    });

    const maxOrderPos = ordering[ordering.length - 1];
    ordering[unknownOrderDays] = ordering[ordering.length - 1] + 1;

    let totals = [];
    let outData = [];
    data.meta.ranges.forEach(function (name, i) {
      outData[i] = new Array(data.meta.order_days_config.length);
      data.meta.order_days_config.forEach(function (range, j) {
        outData[i][j] = 0
      });
      totals[i] = 0
    });

    let orderDaysCatsFormat = function (out, range) {
      if (range[0] === unknownOrderDays) return out.push('Unknown');
      if (range[0] === 0) return out.push(`<${range[1] + 1}`);
      if (range[1] === maxOrderDays) return out.push(`>${range[0] - 1}`);
      if (range[0] === range[1]) return out.push(range[0].toString());
      out.push(`${range[0]}-${range[1]}`);
    };

    let catLabels = [];
    data.meta.order_days_config.forEach(function (range) {
      orderDaysCatsFormat(catLabels, range)
    });

    const getPosition = function (val) {
      if (val >= (ordering.length - 1)) return maxOrderPos;
      return ordering[val];
    };

    // const filters = args.__context.$store.state.dashboard.dashboards.antibiotics_order_length.filters.date_range_options_type

    let avg = 0, totalCount = 0;
    data.data.forEach((val) => {
      if ((val.order_days < 1)) return;
      avg += (val.order_days * val.count);
      totalCount += val.count;
    });

    data.data.forEach( (val) => {
      // An order may be discontinued/stopped before it starts don't count these
      //
      if ((val.order_days < 1) && (val.order_days !== unknownOrderDays)) return;
      let pos = getPosition(val.order_days);
      outData[val.range][pos] += val.count;
      totals[val.range] += val.count
    });

    let percentagesOut = {};
    for (let i = 0; i < catLabels.length; i++) {
      let item = {catLabel: catLabels[i], cat: i};
      for (let j = 0; j < outData.length; j++) {
        const dateRange = data.meta.groupings[j];
        item[dateRange] = outData[j][i];
        item['avg_days'] = (avg/totalCount).toFixed(1);
        item['total_count'] = totalCount;
        item[`${dateRange}_percent`] = (totals[j] !== 0 ? ((item[dateRange] / totals[j]) * 100).toFixed(2) : 0);
        item.days = {from: data.meta.order_days_config[i][0], to: data.meta.order_days_config[i][1]}
        item.dates = {from: data.meta.ranges[j][0], to: data.meta.ranges[j][1]}
      }
      percentagesOut[i] = item
    }

    data.meta.groupings.forEach((group) => {
      data.meta.labels[`${group}_percent`] = data.meta.labels[group]
    });
    data.meta.labels['avg_days'] = args.__context.$t('avg_pres_len')

    return {data: Object.values(percentagesOut), filters: data.filters, meta: data.meta};
  },

  orderLengthBreakdownDataFilter(data, _args) {
    let collectByDrug = {}
    data.data.forEach(function (val) {
      collectByDrug[val.drug_label] ||= {drug_label: val.drug_label, count: 0, location_data: []}
      collectByDrug[val.drug_label].count += val.count
      collectByDrug[val.drug_label].location_data.push({
        location_label: val.location_label,
        primary_dose_level: val.primary_dose_level,
        primary_dose_units: val.primary_dose_units,
        form_label: val.form_label,
        count: val.count
      })
    });

    return {
      data: Object.values(collectByDrug).sort((a, b) => b.count - a.count),
      filters: data.filters,
      meta: data.meta
    };
  }
}