import Vue from 'vue'
import CommonChartFilters from './chart/common_filters'
import OmittedFilters from './chart/omitted_doses_filters'
import AntibioticFilters from './chart/antibiotics_filters'
import ParkinsonsFilters from "./chart/parkinsons_filters";
import GeneralFilters from "./chart/general_filters";
import PatientFilters from "./chart/patient_filters";
import PerformanceFilters from "./chart/performance_filters";
import MonitoringFilters from "./chart/monitoring_filters";

import CommonTableFilters from './table/common_filters'

const DATA_FILTERS = {
  'identity': CommonChartFilters.identity,
  'omittedReasons': OmittedFilters.omittedReasonsDataFilter,
  'omittedReasonsWardCompare': OmittedFilters.omittedReasonsWardCompareDataFilter,
  'omittedDosesEarlyLateTrendsDataFilter': OmittedFilters.omittedDosesEarlyLateTrendsDataFilter,
  'orderLengthDataFilter': AntibioticFilters.orderLengthDataFilter,
  'orderLengthBreakdownDataFilter': AntibioticFilters.orderLengthBreakdownDataFilter,
  'routeDataFilter': AntibioticFilters.routeDataFilter,
  'drugDelayCountFilter': ParkinsonsFilters.drugDelayCountFilter,
  'drugDelayTimelineFilter': ParkinsonsFilters.drugDelayTimelineFilter,
  'selectivePickFilter': CommonChartFilters.selectivePickFilter,
  'usageFilter': GeneralFilters.usageFilter,
  'patientOmittedBreakdownFilter': PatientFilters.patientOmittedBreakdownFilter,
  'patientUnvalidatedDrugBreakdownFilter': PatientFilters.patientUnvalidatedDrugBreakdownFilter,
  'parkinsonsEarlyLateTrendsDataFilter': ParkinsonsFilters.parkinsonsEarlyLateTrendsDataFilter,
  'performanceWardDataFilter': PerformanceFilters.performanceWardDataFilter,
  'passthrough': CommonChartFilters.passthrough,
  'patientUsageStatsFilter':  MonitoringFilters.patientUsageStatsFilter,
};

const TABLE_VALUE_FILTERS = {
  identity: CommonTableFilters.identity,
  percentage: CommonTableFilters.percentage,
  percentTag: CommonTableFilters.percentTag,
  titleize: CommonTableFilters.titleize,
  appendage: CommonTableFilters.appendage,
  prettyDate: CommonTableFilters.prettyDate,
  prettyTime: CommonTableFilters.prettyTime,
  prettyDatetime: CommonTableFilters.prettyDatetime,
  datetimeToMinute: CommonTableFilters.datetimeToMinute,
  prettyDelay: CommonTableFilters.prettyDelay,
  rrule: CommonTableFilters.rrule,
  yesNo: CommonTableFilters.yesNo,
  toDecimalTwo: CommonTableFilters.toDecimalTwo
}

Vue.filter('dataFilterSelector', (data, filter, filterArgs) => {
  return (DATA_FILTERS[filter] || DATA_FILTERS['identity'])(data, filterArgs)
});

Vue.filter('tableFilterSelector', (data, filter, filterArgs) => {
  return (TABLE_VALUE_FILTERS[filter] || TABLE_VALUE_FILTERS['identity'])(data, filterArgs)
});

Vue.filter('dataSorter', (data, sortInfo) => {
  if (!sortInfo || !sortInfo.field) {
    return data
  }
  return _.orderBy(data, [sortInfo.field], [sortInfo.dir])
});

Vue.filter('dateRangeOptionsFilter', (data, optionsType) => {
  return _.filter(data, (item) => {
    if(item.value === 'custom') return true
    if(item.value.startsWith('last') && (optionsType === 'past' || optionsType === 'today_past')) return true;
    if(item.value.startsWith('today') && (optionsType === 'today_past' || optionsType === 'future')) return true;
    return (item.value.startsWith('next')) && optionsType === 'future'
  })
});

Vue.filter('dateFormatter', (date, formatter)=> {
  if(!formatter) {
    return date;
  }

  if (date) {
    return moment(date).format(formatter)
  }
})

Vue.filter('squareReplace', (str, replaceOpts)=> {
  if (!str) return;

  let out = str;
  Object.keys(replaceOpts).forEach((key)=> {
    let replaceStr = replaceOpts[key].text;
    if(replaceOpts[key].transform) {
      replaceStr = _[replaceOpts[key].transform](replaceStr)
    }
    out = str.replaceAll(`[[${key}]]`, replaceStr)
  });
  return out;
})



